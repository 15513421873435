<template>
    <div class="head">
        <div v-if="data?.title" class="title">
            <h1>{{ data?.title }}</h1>
        </div>
    </div>
    <div class="container flex wrap center vcenter" :class="{ 'dialog': properties['dialog'] === 'YES' }">
        <form class="flex wrap initial" :class="[{ 'fullwidth': properties['fullwidth'] === 'YES' }
            , { 'center': center }]" @keyup="keyup" @keydown="keydown">
            <template v-for="(meta, index) in metas.filter((meta) => { return meta.showable })" :key="index">
                <div v-if="meta.type === 'space'" v-show="visibilities[meta.key] === 'visible'"
                    :style="{ 'width': meta.style ? JSON.parse(meta.style)['width'] : '100%' }">
                </div>
                <div v-else-if="meta.type === 'span'" v-show="visibilities[meta.key] === 'visible'"
                    class="flex center vcenter"
                    :style="{ 'width': meta.style ? JSON.parse(meta.style)['width'] : '100%' }">
                    <span :style="Object.assign(meta.style ? JSON.parse(meta.style) : ''
                        , { 'width': '100% !important' })">
                        {{ meta.name }}
                    </span>
                </div>
                <div v-else-if="meta.type === 'line'" class="line">
                </div>
                <Tabs v-else-if="meta.type === 'tab' || meta.type === 'step'"
                    v-show="visibilities[meta.key] === 'visible'" :meta="meta" :vm="models[meta.key]"
                    :disabled="disabled[meta.key]" @onchange="onchange">
                </Tabs>
                <Switch v-else-if="meta.type === 'switch'" v-show="visibilities[meta.key] === 'visible'" :meta="meta"
                    :vm="models[meta.key]" :disabled="disabled[meta.key]" @onchange="onchange"></Switch>
                <DragBox v-else-if="meta.type === 'files'" v-show="visibilities[meta.key] === 'visible'" :meta="meta"
                    :vm="models[meta.key]" :id="id" :disabled="disabled[meta.key]" @onchange="onchange"></DragBox>
                <MultiParams v-else-if="meta.type === 'multiparams'" v-show="visibilities[meta.key] === 'visible'"
                    :meta="meta" :vm="models[meta.key]" :validate="errors[meta.key]" :disabled="disabled[meta.key]"
                    :ctrl="ctrl" @onchange="onchange">
                </MultiParams>
                <Reply v-else-if="meta.type === 'reply'" :meta="meta" :id="id"></Reply>
                <Accordion v-else-if="meta.type === 'acc'" :title="meta.name"
                    :description="base.Entities().parse(meta.extras)['des']" :id="id">
                </Accordion>
                <MetaBox v-else v-show="visibilities[meta.key] === 'visible'" :meta="meta" :vm="models[meta.key]"
                    :id="id" :active="focus === meta.key" :validate="errors[meta.key]" :disabled="disabled[meta.key]"
                    :ctrl="ctrl" @onfocus="onfocus" @onchange="onchange">
                </MetaBox>
            </template>
            <div class="flex wrap center vcenter">
                <template v-for="(button, index) in buttons" :key="index">
                    <Button :meta="button" :active="loading[button.key]" :disabled="disabled[button.key]"
                        @onclick="onsubmit">
                    </Button>
                </template>
            </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
import MetaBox from './Inputs/MetaBox.vue'
import Switch from './Inputs/Switch.vue'
import Tabs from './Inputs/Tabs.vue'
import DragBox from './Inputs/DragBox.vue'
import MultiParams from './Inputs/MultiParams.vue'
import Reply from './Inputs/Reply.vue'
import Button from './Inputs/Button.vue'
import Accordion from './Accordion.vue'
import base from '@/base/base'

export default {
    name: 'Form',
    props: ['entity_type', 'entity', 'id', 'center'],
    inject: ['base', 'api', 'authentication', 'notification', 'entities'],
    components: { MetaBox, Switch, Tabs, MultiParams, DragBox, Reply, Accordion, Button },
    created() {
        new URLSearchParams(window.location.search).forEach((value, key) => {
            this.models[key] = value
        })
        this.init()
    },
    data() {
        return {
            data: this.entity || eval(localStorage.getItem('entities') || []).filter((entity) => {
                return entity.entity_type === this.entity_type.toUpperCase()
            })[0],
            properties: {},
            options: {},
            metas: eval(localStorage.getItem('metas') || []).filter((meta) => {
                return (meta.entity_type === this.entity_type.toUpperCase()
                    || this.base.Entities().parse(meta.extras)['entity_type'] === this.entity_type.toUpperCase())
                    && meta.status !== 101
            }).sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
            buttons: [],
            accesses: eval(localStorage.getItem('accesses') || []).filter((access) => {
                return access.items.toUpperCase().startsWith(`${this.entity_type.toUpperCase()}_FORM`)
                    || access.items === 'PERSIST'
            }),
            dynamic: ['ACCOUNT', 'GROUP', 'ROLE', 'FOLDER', 'FILE']
                .includes(this.entity_type.toUpperCase()),
            models: {},
            values: [],
            files: [],
            temporaryid: '',
            hints: {},
            focus: '',
            errors: {},
            disabled: {},
            visibilities: {},
            loading: {},
            ctrl: false
        }
    },
    watch: {
        models: {
            handler(newModels, oldModels) {
                this.visibility()
                this.metas.forEach((meta) => {
                    if (meta.extras && meta.extras.includes('key=synthetic')) {
                        let value = ''
                        let keys = this.base.Entities().parse(meta.extras)['synthetic']
                        if (keys.includes('|'))
                            keys.split('|').forEach((key) => value += key.includes('key(')
                                ? this.models[key.replace('key(', '').replace(')', '')] || '' : key)
                        else
                            value = this.models[keys]
                        if (this.base.Util().validate(value.replaceAll(' ', '')))
                            this.models[meta.key] = value
                    }
                })
            },
            deep: true
        },
        errors: {
            handler(newErrors, oldErrors) {
                this.metas.forEach((meta) => {
                    if (this.errors[meta.key] === undefined)
                        delete this.errors[meta.key]
                })
            },
            deep: true
        }
    },
    methods: {
        init() {
            this.accesses.forEach((access) => {
                access.items = access.items.replaceAll(`${this.entity_type.toUpperCase()}_FORM_`, '').toLowerCase()
                access.action = access.action.toLowerCase()
            })
            this.properties = this.base.Entities().parse(this.data?.properties)
            this.options = this.base.Entities().parse(this.data?.options)

            if (this.base.Util().validate(this.options)) {
                let buttons = this.options['button']
                if (Array.isArray(buttons))
                    buttons.forEach((button) => {
                        this.base.Entities().button(button
                            , (response) => this.buttons.push(response[0]))
                    })
                else
                    this.buttons = this.base.Entities().button(buttons
                        , (response) => this.buttons = response)
            }

            if (this.base.Util().validate(this.id)) {
                this.attachments()
                this.base.API().post(this.properties['FIND'], { data: { id: Number(this.id) } }
                    , (response) => {
                        if (this.base.Util().success(response)) {
                            this.values = response.data.data
                            this.values.forEach(value => {
                                const meta = this.metas.find((meta) => meta.key === value.key)
                                if (meta) {
                                    if (meta.type === 'multiparams') {
                                        value.value.split(';').forEach((element) => {
                                            let row = element.split(':')[0]
                                            let values = element.split(':')[1].split(',')
                                            for (const key in values) {
                                                let property = values[key].split('=')
                                                if (!this.base.Util().validate(this.models[value.key]))
                                                    this.models[value.key] = {}
                                                if (!this.base.Util().validate(this.models[value.key][row]))
                                                    this.models[value.key][row] = {}
                                                this.models[value.key][row][property[0]] = property[1]
                                            }
                                        })
                                    } else if (meta.type === 'switch')
                                        this.models[value.key] = value.value === 'True' || value.value === 'true' ? true : false
                                    else
                                        this.models[value.key] = value.value
                                }
                            })
                        }
                    }, (error) => {

                    })
            }
            this.visibility()
            this.metas.forEach((meta) => {
                if (meta.extras) {
                    if (meta.extras.includes('key=default')
                        && !this.base.Util().validate(this.models[meta.key])) {
                        let _default = this.base.Entities().parse(meta.extras)['default']
                        if (Array.isArray(_default))
                            _default.forEach((element) => this.models[meta.key] = element)
                        else
                            this.models[meta.key] = _default
                    }
                    if (meta.extras.includes('key=env')
                        && !this.base.Util().validate(this.models[meta.key])) {
                        let env = this.base.Entities().parse(meta.extras)['env']

                        if (this.$cookies.get(env))
                            this.models[meta.key] = this.$cookies.get(env)
                        else
                            this.base.API().post('Data/Value/Find', {
                                data: {
                                    parent_id: `AC${this.$cookies.get('uid')}`,
                                    key: env
                                }
                            }, (response) => {
                                if (this.base.Util().success(response))
                                    if (this.base.Util().validate(response.data.data))
                                        this.models[meta.key] = response.data.data[0].value
                            })
                    }
                    if (meta.extras.includes('key=synthetic')) {
                        let value = ''
                        let keys = this.base.Entities().parse(meta.extras)['synthetic']
                        if (keys.includes('|'))
                            keys.split('|').forEach((key) => value += key.includes('key(')
                                ? this.models[key.replace('key(', '').replace(')', '')] || '' : key)
                        else
                            value = this.models[keys]
                        if (this.base.Util().validate(value.replaceAll(' ', '')))
                            this.models[meta.key] = value
                    }
                }

                let access = this.accesses.find((access) => (access.items === meta.key || access.items === 'persist')
                    && (access.action !== 'visible'))
                if (access) {
                    if (access.action === 'accept' || access.action === 'deny')
                        this.disabled[meta.key] = meta.locked || (!meta.editable && this.id)
                            || access.action === 'deny'
                    else
                        this.disabled[meta.key] = true
                } else
                    this.disabled[meta.key] = true
            })
        },
        keyup(event) {
            if (event.keyCode === 17)
                this.ctrl = false
        },
        keydown(event) {
            if (event.keyCode === 17)
                this.ctrl = true
        },
        onfocus(obj) {
            this.focus = obj.value ? obj.key : ''
        },
        onchange(obj) {
            this.models[obj.key] = obj.value
            this.hints[obj.key] = obj.hint
            this.errors[obj.key] = obj.error
        },
        visibility() {
            this.metas.forEach((meta) => {
                let access = this.accesses.find((access) => access.items === meta.key
                    && ((access.action === 'visible' || access.action === 'hidden'
                        || access.action === 'gone')))
                if (meta.extras && meta.extras.includes('key=v-key')) {
                    let extras = this.base.Entities().parse(meta.extras)
                    if (extras) {
                        let key = extras['v-key']
                        if (typeof (key) === 'string')
                            this.visibilities[meta.key] = this.models[key] === extras[key] || extras[key] === 'any'
                                ? access ? access.action : 'visible'
                                : 'gone'
                        else if (Array.isArray(key)) {
                            let visibility = access ? access.action : 'visible'
                            for (const element in key) {
                                if (extras[key[element]] !== 'any')
                                    if (this.models[key[element]] !== extras[key[element]]) {
                                        visibility = 'gone'
                                        break
                                    }
                            }
                            this.visibilities[meta.key] = visibility
                        }

                        if (this.visibilities[meta.key] === 'gone') {
                            key = extras['o-key']
                            if (typeof (key) === 'string') {
                                let m = this.metas.find((meta) => meta.key === key)
                                this.visibilities[meta.key] = (this.models[key] === extras[key] || extras[key] === 'any') && m
                                    ? access ? access.action : 'visible'
                                    : 'gone'
                            }
                            else if (Array.isArray(key)) {
                                let visibility = access ? access.action : 'visible'
                                for (const element in key) {
                                    if (this.models[key[element]] !== extras[key[element]]) {
                                        visibility = 'gone'
                                        break
                                    }
                                }
                                this.visibilities[meta.key] = visibility
                            }
                        }
                    }
                } else if (access)
                    this.visibilities[meta.key] = access.action
                else
                    this.visibilities[meta.key] = 'visible'
            })
        },
        attachments() {
            this.base.API().post('/IO/Attachment/Find', { data: { parent_id: this.id, status: 100 } }
                , (response) => {
                    if (this.base.Util().success(response))
                        response.data.data.forEach((file) => {
                            let meta = this.metas.find((meta) => meta.key === (file.extras.includes(':')
                                ? file.extras.split(':')[0]
                                : file.extras))
                            if (meta)
                                if (meta.type === 'files') {
                                    if (!this.models[file.extras])
                                        this.models[file.extras] = []
                                    this.models[file.extras].push(file)
                                } else if (meta.type === 'multiparams') {
                                    let keys = file.extras.split(':')
                                    if (!this.models[keys[0]])
                                        this.models[keys[0]] = {}
                                    if (!this.models[keys[0]][keys[1]])
                                        this.models[keys[0]][keys[1]] = {}
                                    this.models[keys[0]][keys[1]][keys[2]] = file
                                } else
                                    this.models[file.extras] = file
                        })
                })
        },
        parse(meta, value) {
            if (meta.type === 'multiparams') {
                value = ''
                for (const key of Object.keys(this.models[meta.key])) {
                    let data = ''
                    try {
                        for (const property of Object.keys(this.models[meta.key][key]))
                            if (this.models[meta.key][key][property].prototype !== 'FILE')
                                data += this.base.Util().validate(data)
                                    ? `,${property}=${this.models[meta.key][key][property]}`
                                    : `${property}=${this.models[meta.key][key][property]}`
                            else {
                                let file = this.models[meta.key][key][property]
                                if (!file.source) {
                                    file.extras = `${meta.key}:${key}:${property}`
                                    this.files.push(file)
                                }
                            }
                    } catch (e) {

                    }
                    value += this.base.Util().validate(value)
                        ? `;${key}:${data}`
                        : `${key}:${data}`
                }
            }
            else if (this.dynamic)
                if (meta.type === 'switch')
                    value = value && (value.toLowerCase() === 'true' || value === true) ? 'True' : 'False'
                else
                    value = String(value || '')
            else
                if ((meta.type === 'number' || meta.type === 'amount'))
                    value = value ? Number(value.replaceAll(/,/g, '')) : 0
                else if (meta.type === 'switch')
                    value = value && (String(value).toLowerCase() === 'true' || value === true) ? true : false
                else if (meta.type === 'date')
                    value = Number(this.base.Util().time({ date: value }))
                else if (meta.type === 'dropdown')
                    value = value && !isNaN(value) ? Number(value) : value
            return value
        },
        async onsubmit(meta) {
            let pass = true
            this.loading[meta.key] = true
            this.metas.forEach((meta) => {
                if (this.visibilities[meta.key] !== 'gone') {
                    if (!this.errors[meta.key] || this.errors[meta.key] === false)
                        this.errors[meta.key] = meta.type === 'multiparams'
                            ? this.errors[meta.key]
                            : meta.required && (!this.base.Util().validate(this.models[meta.key]))
                    if (this.errors[meta.key] === true) {
                        this.focus = meta.key
                        this.base.Notification().error({ code: 1000, text: meta.name })
                        pass = false
                    } else if (meta.type === 'multiparams') {
                        Object.keys(this.models[meta.key]).forEach((key) => {
                            eval(localStorage.getItem('metas')).filter((element) => {
                                return element.entity_type === meta.key.toUpperCase()
                            }).forEach((element) => {

                                if (!this.errors[meta.key])
                                    this.errors[meta.key] = {}
                                if (!this.errors[meta.key][key])
                                    this.errors[meta.key][key] = {}

                                this.errors[meta.key][key][element.key] = element.required && (!this.base.Util().validate(this.models[meta.key][key][element.key]))
                                if (this.errors[meta.key][key][element.key] === true) {
                                    this.notification.error({ code: 1000, text: `"${element.name}" در ${meta.name} ردیف "${key.replaceAll('R', '')}"` })
                                    pass = false
                                }
                            })
                        })
                    }
                }
            })

            if (pass) {
                let values = []
                let parent_id = this.base.Util().validate(this.id)
                    ? this.id
                    : this.base.Util().hash(4)
                this.metas.forEach((meta) => {
                    if (meta.entity_type === this.entity_type.toUpperCase()) {
                        if (meta.type !== 'file' && meta.type !== 'files' && meta.type !== 'span' && meta.type !== 'step') {
                            // let id = Number(Math.floor(Math.random() * 1000))
                            let id = 0
                            if (this.dynamic && this.values) {
                                let value = this.values.find((value) => value.key === meta.key)
                                if (value)
                                    id = value.id
                            }

                            values.push({
                                id: id,
                                parent_id: parent_id,
                                key: meta.key,
                                value: this.parse(meta, this.models[meta.key])
                            })
                        } else if ((meta.type === 'file' || meta.type === 'files')
                            && this.base.Util().validate(this.models[meta.key]))
                            if (Array.isArray(this.models[meta.key])
                                && this.models[meta.key].length > 0)
                                this.models[meta.key].forEach((file) => {
                                    if (!file.source)
                                        this.files.push(file)
                                })
                            else if (!this.models[meta.key].source)
                                this.files.push(this.models[meta.key])
                    }
                })

                if (this.base.Util().validate(this.files)) {
                    let response = await this.base.API().upload(this.id || this.temporaryid || '', this.files)
                    if (this.base.Util().success(response)) {
                        if (this.base.Util().validate(response.data.temporaryid))
                            this.temporaryid = response.data.temporaryid

                        if (this.base.Util().validate(response.data.uploads)) {
                            let files = []
                            this.files.forEach(file => {
                                if (!response.data.uploads.find(upload =>
                                    upload.name === `${file.name}|${file.extras}` && upload.status === 'success'))
                                    files.push(file)
                            })
                            this.files = files
                        }
                    }

                    if (this.base.Util().validate(this.files)) {
                        this.files.forEach((file) =>
                            this.base.Notification().error({ code: 1005, text: file.name }))
                        pass = false
                        return
                    }
                }

                if (pass)
                    this.base.Entities().submit({
                        entity_type: this.entity_type.toUpperCase(),
                        properties: this.properties,
                        options: this.options,
                        dynamic: this.dynamic,
                        id: this.id,
                        temporaryid: this.temporaryid,
                        data: values,
                        meta: meta
                    }, (obj) => {
                        this.loading[meta.key] = false
                        this.$emit('onresult', obj)
                    })
                else
                    this.loading[meta.key] = false
            } else
                this.loading[meta.key] = false
        }
    }
}
</script>

<style src="../assets/css/form.css" />