<template>
    <button class="option flex vcenter" :style="{ 'background-color': color }" @click.prevent="$emit('onclick')">
        <span class="icon">
            <i href="#" class="fe fe-16" :class="icon"></i>
        </span>
        <span class="text">{{ name }}</span>
    </button>
</template>

<script>
/*eslint-disable */
export default {
    name: 'Option',
    props: ['color', 'icon', 'name']
}
</script>

<style scoped src="../../assets/css/option.css"></style>