<template>
    <div class="switchbox flex wrap space vcenter" :style="meta.style ? JSON.parse(meta.style) : ''">
        <label>{{ meta.name }}</label>
        <div class="switch"
            :class="[{ 'minimized': Boolean(base.Entities().parse(meta.extras)['minimized'] || false) }]">
            <input type="checkbox" v-model="value" :disabled="disabled" />
            <div class="toggle"></div>
            <div class="layer"></div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Switch',
    props: ['meta', 'vm', 'disabled'],
    inject: ['base'],
    data() {
        return {
            value: this.vm
        }
    },
    watch: {
        vm(newVM, oldVM) {
            this.value = this.vm
        },
        value(newValue, oldValue) {
            this.$emit('onchange', {
                key: this.meta.key,
                value: this.value
            })
        }
    }
}
</script>

<style scoped src="../../assets/css/switch.css" />