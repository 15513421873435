<template>
    <div class="social flex center vcenter">
        <div class="flex center vcenter">
            <img src="/images/instagram.png" href="" />
        </div>
        <div class="flex center vcenter">
            <a href="https://t.me/atiyacrowd" target="_blank">
                <img src="/images/telegram.png" />
            </a>
        </div>
        <div class="flex center vcenter">
            <a href="https://Ble.ir/atiyacrowd" target="_blank">
                <img src="/images/bale.png" />
            </a>
        </div>
        <div class="flex center vcenter">
            <a href="https://eitaa.com/atiyacrowd" target="_blank">
                <img src="/images/eitaa.png" />
            </a>
        </div>
        <div class="flex center vcenter">
            <a href="https://www.aparat.com/atiyacrowd" target="_blank">
                <img src="/images/aparat.png" />
            </a>
        </div>
        <div class="flex center vcenter">
            <img src="/images/x.png" />
        </div>
        <div class="flex center vcenter">
            <img src="/images/linkedin.png" />
        </div>
        <div class="flex center vcenter">
            <a href="mailto:info@atiyacrowd.ir" target="_blank">
                <img src="/images/mail.png" />
            </a>
        </div>
        <div class="flex center vcenter">
            <a href="https://www.youtube.com/@useatiyacrowd" target="_blank">
                <img src="/images/youtube.png" />
            </a>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Social',
}
</script>

<style src="../assets/css/social.css"></style>