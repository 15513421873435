<template>
    <div v-if="type === null" class="tickets flex wrap center">
        <template v-for="(ticket, index) in tickets" :key="index">
            <div class="flex wrap center vcenter" @click.prevent="type = ticket.id">
                <img :src="ticket.icon">
                <h4>{{ ticket.name }}</h4>
                <p>{{ ticket.description }}</p>
                <div class="flex wrap center vcenter">
                    <p>{{ ticket.extras }}</p>
                </div>
            </div>
        </template>
    </div>
    <div v-else>
        <Form :entity_type="'REQUEST'"></Form>
    </div>
</template>

<script>
/* eslint-disable */
import Form from './Form.vue'

export default {
    name: 'Ticket',
    components: { Form },
    inject: ['base'],
    created() {
        this.base.API().post('Support/Ticket/Find', { data: { stauts: 100 } }
            , (response) => {
                if (this.base.Util().success(response))
                    if (this.base.Util().validate(response.data.data))
                        this.tickets = response.data.data
            })
    },
    data() {
        return {
            tickets: [],
            type: null
        }
    }
}
</script>

<style src="../assets/css/ticket.css"></style>