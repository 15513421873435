<template>
    <div class="head">
        <div class="title">
            <h1>{{ 'صورت وضعیت مالی' }}</h1>
        </div>
        <div class="options flex">
            <div class="right flex start vcenter">
                <Option :color="'blue'" :icon="'fe-search'" :name="'جست و جو'"></Option>
            </div>
            <div class="left flex end vcenter">
                <!-- <Option :color="'green'" :icon="'fe-download'" :name="'واریز وجه'" @click="dialog = 'payment'">
                </Option> -->
                <!-- <Option :color="'crimson'" :icon="'fe-upload'" :name="'برداشت وجه'" @click="dialog = 'withdrawal'">
                </Option> -->
                <!-- <Option :icon="'fe-users'" :name="'انتقال به حساب دیگران'"></Option> -->
            </div>
        </div>
    </div>
    <List :entity_type="'Transaction'" :page="page" :size="size" @click="dialog = ''"></List>
    <Dialog :data="{
        entity_type: 'PAYMENT',
        fullwidth: true,
        title: 'واریز وجه',
        type: 'form'
    }" @onresult="onresult" :class="{ 'show': dialog === 'payment' }"></Dialog>
    <Dialog :data="{
        entity_type: 'WITHDRAWAL',
        halfwidth: true,
        title: 'برداشت وجه',
        type: 'form'
    }" @onresult="onresult" :class="{ 'show': dialog === 'withdrawal' }"></Dialog>
</template>

<script>
/*eslint-disable */
import Option from '../components/Inputs/Option.vue'
import List from '../components/List.vue'
import Dialog from '../components/Dialog.vue'

import axios from 'axios'

export default {
    name: 'Transaction',
    props: ['page', 'size'],
    inject: ['base'],
    components: { Option, List, Dialog },
    created() {
        this.$route.hash.split('&')
            .map(part => part.replace(/^#/, ''))
            .forEach(param => setTimeout(() => {
                if (param.toLowerCase() === 'payment')
                    this.dialog = 'payment'
                else if (param.toLowerCase() === 'withdrawal')
                    this.dialog = 'withdrawal'
            }, 1500))
    },
    data() {
        return {
            dialog: ''
        }
    },
    methods: {
        onchange(obj) {
            this.amount = Number(obj.value.replaceAll(',', ''))
        },
        onresult(obj) {
            if (this.base.Util().success({ data: obj }) && obj.data[0].channel === 'SADAD') {
                axios.get(`https://barmanazhandco.com/wp-content/plugins/sadadpsp/Send.php?token=${'fIGUfQYqiZLreMShKIUwzOPjd3Fg3xVl'}&amount=${obj.data[0].amount}&oid=${obj.data[0].id}`)
                    .then((response) => {
                        if (response && response.data && response.data.ResCode === '0') {
                            window.location
                                .replace(`https://barmanazhandco.com/wp-content/plugins/sadadpsp/Redirect.php?TOKEN=${response.data.Token}`);
                        }
                    })
            } else if (this.base.Util().success({ data: obj })) {
                this.base.Notification().toast('نتیجه', 'عملیات ثبت با موفقیت انجام گردید.', 'success')
                this.dialog = false
            }
        }
    }
}
</script>

<style src="../assets/css/transactions.css"></style>