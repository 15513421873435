<template>
    <div class="button">
        <button type="button" :class="{ 'active': active }" :style="meta.style
            ? JSON.parse(meta.style)
            : ''" @click.prevent="$emit('onclick', meta)">
            <div class="loading">
                <span></span>
            </div>
            <p>{{ meta.name }}</p>
        </button>
    </div>
</template>

<script>
/* eslint-disable */

export default {
    name: 'Button',
    props: ['meta', 'active', 'disabled'],
    created() {
    }
}
</script>

<style scoped src="../../assets/css/button.css"></style>