<template>
    <div class="content">
        <div class="header flex wrap center">
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Root',
    inject: ['base'],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            slides: [

            ]
        }
    }
}
</script>

<style scoped src="../assets/css/root.css"></style>