<template>
    <Select2 :id="id"
        :class="['select', { 'has': value && base.Util().validate(String(value)) }, { 'focused': clicked }]"
        v-model="value" :disabled="disabled" :options="options"
        :settings="{ allowClear: base.Entities().parse(meta.extras)['clear'] && !multiple, multiple: multiple }"
        @click="click">
    </Select2>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Dropdown',
    props: ['meta', 'vm', 'active', 'disabled'],
    inject: ['base'],
    created() {
        setInterval(() => this.clicked = ($(`#${this.id}~.select2`).hasClass('select2-container--focus')
            || $(`#${this.id}~.select2`).hasClass('select2-container--open'))
            && !$(`#${this.id}~.select2`).hasClass('select2-container--disabled'), 1)
        this.base.Entities().data(this.meta.extras, 'dynamic', (data) => this.options = data)
    },
    data() {
        return {
            id: this.base.Util().hash(4),
            clicked: false,
            options: [],
            multiple: this.meta.type === 'multidrop',
            value: this.vm || '',
            hint: ''
        }
    },
    watch: {
        vm(newVM, oldVM) {
            this.value = this.multiple ? this.vm.split(',') : this.vm
        },
        active(newActive, oldActive) {
            if (this.active)
                $('html, body').animate({
                    scrollTop: 0
                }, "slow")
        },
        clicked(newClicked, oldClicked) {
            this.$emit('onfocus', this.clicked && !this.disabled)
        },
        value(newValue, oldValue) {
            this.$emit('onchange', {
                value: String(this.value),
                hint: this.hint
            })
        }
    },
    methods: {
        click() {
            $('.select2-dropdown--below').addClass(this.multiple ? 'multiple' : 'single')
        }
    }
}
</script>