<template>
    <div class="meta" :class="[{ 'area': meta.type === 'textarea' }]"
        :style="{ 'width': meta.style ? JSON.parse(meta.style)['width'] : '100%' }">
        <div class="metabox flex wrap center vend" :class="[{ 'active': base.Util().validate(model) || focus },
        { 'error': error && !focus }]" :style="meta.style ? JSON.parse(meta.style) : ''">
            <TextArea v-if="meta.type === 'textarea'" :meta="meta" :vm="vm" :active="active" :disabled="disabled"
                :ctrl="ctrl" @onfocus="onfocus" @onchange="onchange"></TextArea>

            <Dropdown v-else-if="meta.type === 'dropdown' || meta.type === 'multidrop'" :meta="meta" :vm="vm"
                :active="active" :disabled="disabled" @onfocus="onfocus" @onchange="onchange">
            </Dropdown>

            <Upload v-else-if="meta.type === 'file'" :meta="meta" :vm="vm" :id="id" @onchange="onchange">
            </Upload>

            <TextBox v-else :meta="meta" :vm="vm" :active="active" :validate="validate" :disabled="disabled"
                :ctrl="ctrl" @onfocus="onfocus" @onchange="onchange"></TextBox>
            <div class="focus" :class="{ 'error': error }"></div>
            <label class="flex around">{{ label || meta.name }}</label>
        </div>
        <span v-if="base.Util().validate(hint)" class="hint">{{ hint }}</span>
    </div>
</template>

<script>
/* eslint-disable */
import TextBox from './TextBox.vue'
import TextArea from './TextArea.vue'
import Dropdown from './Dropdown.vue'
import Upload from './Upload.vue'

export default {
    name: 'MetaBox',
    props: ['meta', 'vm', 'id', 'active', 'validate', 'disabled', 'ctrl'],
    inject: ['base'],
    components: { TextBox, TextArea, Dropdown, Upload },
    data() {
        return {
            model: this.vm,
            focus: false,
            hint: '',
            error: this.validate,
            label: ''
        }
    },
    watch: {
        vm(newVm, oldVM) {
            this.label = (this.vm && this.vm.name) || ''
        },
        validate(newValidate, oldValidate) {
            this.error = this.validate ? this.validate : false
        }
    },
    methods: {
        onfocus(focus) {
            this.focus = focus

            this.$emit('onfocus', {
                key: this.meta.key,
                value: this.focus
            })
        },
        onchange(obj) {
            this.model = obj.value
            this.hint = obj.hint
            this.error = obj.error

            this.label = this.model && this.model.prototype && this.model.prototype === 'FILE'
                ? this.model.name
                : ''

            this.$emit('onchange', {
                key: this.meta.key,
                value: this.model,
                hint: this.hint,
                error: this.error
            })
        }
    }
}
</script>

<style src="../../assets/css/metabox.css" />