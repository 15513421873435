/* eslint-disable */
import cookies from 'vue-cookies'

const crypto = require('crypto-js')
const excluded = []

function generate() {
  let token = cookies.get('identifier')
  return crypto.AES.encrypt(JSON.stringify({
    issue: Date.now(),
    expire: Date.now() + 30000,
    token: token ? token : ''
  }), crypto.enc.Utf8.parse('JXqZaCyxPq1UshzxbC7/yofDmyNeUQI='), {
    iv: crypto.enc.Utf8.parse('ER9PZNsl/rEl+7uk'),
    mode: crypto.mode.CBC
  }).toString()
}

function session() {
  const ua = window.navigator.userAgent
  return {
    data: {
      token: cookies.get('identifier') ? cookies.get('identifier') : '',
      device: 'WebKit',
      os: ua.includes('Windows') ?
        'Windows' : ua.includes('Linux') ?
        'Linux' : ua.includes('mac') ?
        'macOS' : 'Other',
      agent: `Web ${ua}`,
      ip: localStorage.getItem('ipaddr')
    }
  }
}

function identify(path) {
  excluded = path
  if (!cookies.get('identifier') && !excluded.includes(window.location.pathname.toLowerCase()))
    logout()
}

function logout() {
  if (window.location.pathname !== '/Authentication') {
    localStorage.clear()
  }
  ['identifier', 'uid', 'gid', 'rid', 'username', 'mobile', 'display_name']
  .forEach((cookie) => cookies.remove(cookie))
  if (!excluded.includes(window.location.pathname.toLowerCase()))
    window.location.pathname = '/Authentication'
}

export default {
  generate,
  session,
  identify,
  logout
}
