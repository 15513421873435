/* eslint-disable */
import {
  notify
} from '@kyvg/vue3-notification'

function error(obj) {
  let text = ''
  switch (obj.code) {
    case 401:
      text = 'دسترسی غیرمجاز!'
      break
    case 1000:
      text = `مقدار "${obj.text}" ضروری یا نامعتبر است!`
      break
    case 1001:
      text = `پسوند فایل "${obj.text}" غیر مجاز است!`
      break
    case 1002:
      text = `بارگذاری فایل "${obj.text}" به علت حجم غیرمجاز انجام نشد!`
      break
    case 1003:
      text = `بارگذاری فایل "${obj.text}" به علت عبور از حجم مجاز آپلود انجام نشد! `
      break
    case 1004:
      text = `بارگذاری فایل "${obj.text}" به علت محدودیت تعداد فایل مجاز انجام نشد! `
      break
    case 1005:
      text = `بارگذاری فایل "${obj.text}" با خطا مواجه شد!`
      break
    case 4004:
      if (obj.referr === 'login')
        text = 'شناسه ملی مورد جستجو یافت نشد!'
      else
        text = 'مورد جست‌جو یافت نشد!'
      break
    case 4005:
      if (obj.referr === 'login')
        text = 'کاربری با شناسه ملی ذیل از پیش ثبت نام شده است!'
      else {
        let metas = eval(localStorage.getItem('metas'))
        let name = obj.text
        if (metas) {
          let meta = metas.find((meta) => meta.key === obj.text)
          if (meta)
            name = meta.name
        }
        text = `تعارض داده در "${name}" رخ‌داد!`
      }
      break
    case 4008:
      text = 'کد تائید نامعتبر است!'
      break
    case 4009:
      text = 'کد تائید منقصی گردید!'
      break
    default:
      text = `خطای ${obj.code} رخ‌داد`
  }
  toast('خطا', text, 'error')
}

function toast(title, text, type = 'info', id = '', duration = 6000) {
  notify({
    id: id,
    title: title,
    text: text,
    type: type,
    duration: duration,
  })
}

function close(id) {
  notify.close(id)
}

export default {
  error,
  toast,
  close
}
