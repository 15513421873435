<template>
    <div class="chart">
        <div class="earned flex wrap">
            <div class="right">
                <p>{{ String(Number(this.amount.replaceAll(',', '')) + 10000000000).replace(/\B(?=(\d{3})+(?!\d))/g,
                    ',') }} ریال</p>
            </div>
            <div class="left">
                <p>توسط {{ count + 1 }} سرمایه‌گذار</p>
            </div>
        </div>
        <div class="progress">
            <div
                :style="`width: ${Number(this.amount.replaceAll(',', '')) / Number(this.total.replaceAll(',', '')) + 10}%`">
            </div>
        </div>
        <div class="earned flex wrap">
            <div class="right">
                <p>{{ Math.round((Number(this.amount.replaceAll(',', '')) / Number(this.total.replaceAll(',', '')) + 10)
                    * 100) / 100 }} درصد از
                    <span>{{ total }}
                        ریال</span>
                </p>
            </div>
            <div class="left">
                <p>{{ day_left }} روز مانده</p>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Progress',
    props: ['amount', 'total', 'count'],
    created() {
        this.per_left = Number(this.amount) / Number(this.total)

        const d = new Date();
        let day = d.getDay();

        this.day_left = 30 - day
    },
    data() {
        return {
            per_left: 0,
            day_left: 0
        }
    }
}
</script>

<style src="../assets/css/progress.css"></style>