<template>
    <div class="multiparams">
        <div class="thead flex center vcenter">
            <template v-for="(meta, index) in metas" :key="index">
                <div class="flex wrap center vcenter" :style="meta.style
                    ? { 'width': JSON.parse(meta.style)['width'] }
                    : ''">
                    <p>{{ meta.name }}</p>
                </div>
            </template>
            <div>
            </div>
        </div>
        <div class="tbody flex wrap center vcenter">
            <template v-for="(key, index) in Object.keys(models)" :key="index">
                <row v-if="!models[key].delete" class="flex wrap center vcenter">
                    <template v-for="(meta, j) in metas" :key="j">
                        <div class="flex wrap center vcenter" :style="meta.style
                            ? { 'width': JSON.parse(meta.style)['width'] }
                            : ''">
                            <Switch v-if="meta.type === 'switch'" :meta="meta" :vm="models[key][meta.key]" :ctrl="ctrl"
                                @onchange="(obj) => this.models[`R${index}`][obj.key] = obj.value">
                            </Switch>
                            <MetaBox v-else :meta="meta" :vm="models[key][meta.key]"
                                :validate="validate ? validate[`R${index}`] ? validate[`R${index}`][meta.key] : false : false"
                                :ctrl="ctrl" @onchange="(obj) => {
                                    this.models[`R${index}`][obj.key] = obj.value
                                    if (this.hints[`R${index}`])
                                        this.hints[`R${index}`][obj.key] = obj.hint
                                    if (this.errors[`R${index}`])
                                        this.errors[`R${index}`][obj.key] = obj.error
                                }">
                            </MetaBox>
                        </div>
                    </template>
                    <div class="flex wrap center vcenter">
                        <img src="/images/plus.png" width="24" height="24" alt="add" @click="add">
                        <img v-if="length > 1" src="/images/xmark.png" width="24" height="24" alt="delete"
                            @click="() => models[key] = { delete: true }">
                    </div>
                </row>
            </template>
        </div>
        <div class="tfooter">
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import MetaBox from './MetaBox.vue'
import Switch from './Switch.vue'

export default {
    name: 'MultiParams',
    props: ['meta', 'vm', 'validate', 'ctrl'],
    inject: ['base'],
    components: { MetaBox, Switch },
    created() {
        if (!this.vm)
            this.add()
    },
    data() {
        return {
            metas: eval(localStorage.getItem('metas')).filter((meta) => {
                return meta.entity_type === this.meta.key.toUpperCase() && meta.status !== 101
            }),
            models: {},
            hints: {},
            errors: {},
            length: 0
        }
    },
    watch: {
        vm: {
            handler(newVM, oldVM) {
                if (this.vm && Object.keys(this.models).length !== Object.keys(this.vm).length)
                    this.models = this.vm
            },
            deep: true
        },
        models: {
            handler(newModels, oldModels) {
                this.length = 0
                Object.keys(this.models).forEach(key => {
                    if (!this.models[key].delete)
                        this.length += 1
                })
                let models = this.reformat(this.models)
                let hints = this.reformat(this.hints)
                let errors = this.reformat(this.errors)

                this.$emit('onchange', {
                    key: this.meta.key,
                    value: models,
                    hint: hints,
                    error: errors
                })
            },
            deep: true
        }
    },
    methods: {
        add() {
            let keys = Object.keys(this.models)
            let index = this.base.Util().validate(keys)
                ? Number(keys[keys.length - 1].replaceAll('R', '')) + 1
                : 0
            this.models[`R${index}`] = {}
            this.hints[`R${index}`] = {}
            this.errors[`R${index}`] = {}
        },
        reformat(source) {
            let keys = Object.keys(source)
            let models = {}
            for (let index = 0; index < keys.length; index++)
                if (!source[keys[index]].delete)
                    models[`R${index}`] = source[keys[index]]
            return models
        }
    }
}
</script>

<style scoped src="../../assets/css/multiparams.css"></style>