/* eslint-disable */
import api from './api'
import notification from './notification'
import util from './util'

function submit(obj, res = (obj) => {}) {
  const body = {
    entity_type: obj.entity_type,
    temporaryid: obj.temporaryid
  }

  if (obj.meta.action === 'post') {
    if (obj.dynamic)
      Object.assign(body, {
        data: obj.data
      })
    else {
      let entity = {}
      entity['id'] = util.validate(obj.id) ? Number(obj.id) : 0
      obj.data.forEach((value) => entity[value.key] = value.value)
      body['data'] = [entity]
    }

    if (util.validate(obj.id))
      api.put(obj.properties['UPDATE'], body, (response) => {
        if (util.success(response)) {
          if (obj.properties['success'] === 'YES')
            notification.toast('نتیجه عملیات', 'تغییرات با موفقیت ذخیره گردید', 'success')
          if (obj.properties['close'] === 'YES')
            setTimeout(() => window.close(), 1000)
          else if (obj.properties['reload'] === 'YES')
            setTimeout(() => location.reload(), 1000)
        } else
          notification.error({
            code: response.data.errorCode,
            referr: obj.entity_type
          })
        res(response.data)
      }, (error) => {})
    else
      api.post(obj.meta.endpoint || obj.properties['POST'], body, (response) => {
        if (util.success(response)) {
          if (obj.properties['success'] === 'YES')
            notification.toast('نتیجه عملیات', 'ثبت داده‌ها با موفقیت انجام گردید', 'success')
          if (obj.properties['reload'] === 'YES')
            setTimeout(() => location.reload(), 1000)
        } else
          notification.error({
            code: response.data.errorCode,
            referr: obj.entity_type,
            text: response.data.message
          })
        res(response.data)
      }, (error) => {})
  }
}

function receive(file) {
  api.download(file.source, (response) => {
    if (util.success(response)) {
      file.raw = response.data
      file.raw.type = file.type
      download(file)
    } else
      notification.error(4004)
  })
}

function download(file) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([file.raw]))
  if (file.raw.type.includes('image/') || file.raw.type.includes('/pdf'))
    window.open(link.href)
  else {
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
  }
}

function parse(extra) {
  let extras = {}
  if (extra)
    extra.split(';').forEach(data => {
      data = data.split(':')[1]
      if (data) {
        data = data.split(',')
        let key = data[0].split('=')[1]
        let value = data[1].split('=')[1]
        if (extras[key]) {
          if (Array.isArray(extras[key])) {
            let values = extras[key]
            values.push(value)
            extras[key] = values
          } else
            extras[key] = [extras[key], value]
        } else
          extras[key] = value
      }
    })
  return extras
}

function data(extras, type = 'dynamic', res = () => {}) {
  let options = []
  if (type === 'dynamic') {
    let data = parse(extras)['data']
    if (data) {
      api.post('Data/Find', {
        data: {
          key: data
        }
      }, (response) => {
        if (util.success(response)) {
          data = parse(response.data.data[0].content)
          if (data)
            Object.keys(data).forEach((element) => {
              options.push({
                id: element,
                text: data[element]
              })
            })
          res(options)
        }
      }, (error) => {

      })
    }
    return options
  } else {
    let data = parse(extras)
    Object.keys(data).forEach((element) => {
      options.push({
        id: element,
        text: data[element]
      })
    })
    res(options)
  }
}

function button(extras, res = () => {}) {
  let options = []
  if (Array.isArray(extras)) {
    extras.forEach((data) => {
      api.post('Data/Button/Find', {
        data: {
          key: data
        }
      }, (response) => {
        if (util.success(response))
          options.push(response.data.data[0])
      }, (error) => {})
    })
  } else
    api.post('Data/Button/Find', {
      data: {
        key: extras
      }
    }, (response) => {
      if (util.success(response)) {
        options.push(response.data.data[0])

        res(options)
      }
    }, (error) => {})
}

function search() {
  params = {}
  new URLSearchParams(window.location.search).forEach((value, key) => {
    params[key] = value
  })
  return params
}

export default {
  submit,
  receive,
  download,
  parse,
  data,
  button,
  search
}
