<template>
    <div class="projects flex wrap center vcenter">
        <div class="row">
            <div class="head flex wrap center">
                <div>
                    <h4>هم‌اکنون می‌توانید سرمایه گذاری کنید!</h4>
                </div>
            </div>
            <div class="body flex wrap center vcenter">
                <template v-for="(entity, index) in rows['entities']" :key="index">
                    <CardView :title="find(entity.id, 'folder_name')" :c_name="find(entity.id, 'company_name')"
                        :profit="find(entity.id, 'interest_rate')" :period="find(entity.id, 'period')"
                        :withdrawal="find(entity.id, 'dividend_period')" :symbols="find(entity.id, 'symobl')"
                        :id="entity.id">
                    </CardView>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import CardView from '@/components/CardView.vue'
export default {
    name: 'Offer',
    inject: ['base'],
    components: { CardView },
    created() {
        this.base.API().post('Identities/Folder/Find', { data: { status: 103 } }
            , (response) => {
                if (this.base.Util().success(response)) {
                    this.rows['entities'] = response.data.entities
                    this.rows['values'] = response.data.data
                }
            }, (error) => {

            })
    },
    data() {
        return {
            rows: { entities: [], values: [] }
        }
    },
    methods: {
        find(id, key) {
            return this.rows.values.find((value) =>
                Number(value.parent_id
                    .replace('AC', '').replace('GR', '')
                    .replace('RO', '').replace('FO', '')
                    .replace('FI', '').replace('VV', '')) === id && value.key === key)?.value || ''
        }
    }
}
</script>