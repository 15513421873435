/* eslint-disable */
import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Project from '../views/Project.vue'
import Projects from '../views/Projects.vue'
import Contact from '../views/Contact.vue'
import Authentication from '../views/Authentication.vue'
import Dashboard from '../views/Dashboard.vue'
import Root from '../views/Root.vue'
import Transaction from '../views/Transaction.vue'
import Funding from '../views/Funding.vue'
import Introduction from '../views/Introduction.vue'
import Investment from '../views/Investment.vue'
import In from '../views/In.vue'
import Offer from '../views/Offer.vue'
import OfferP from '../views/OfferP.vue'

import Form from '../components/Form.vue'
import List from '../components/List.vue'
import Receipt from '../components/Receipt.vue'
import Ticket from '../components/Ticket.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'آتیا کراد | سکوی تأمین مالی جمعی آتیۀ ایرانیان'
    }
  },
  {
    path: '/Project/:id',
    name: 'project',
    component: Project,
    props: true,
    meta: {
      title: 'آتیا کراد | جزئیات طرح'
    }
  },
  {
    path: '/Projects',
    name: 'projects',
    component: Projects,
    meta: {
      title: 'آتیا کراد | طرح‌ها'
    }
  },
  {
    path: '/Contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'آتیا کراد | تماس باما'
    }
  },
  {
    path: '/Funding',
    name: 'funding',
    component: Funding,
    meta: {
      title: 'آتیا کراد | ثبت طرح و تأمین سرمایه'
    }
  },
  {
    path: '/Introduction',
    name: 'introduction',
    component: Introduction,
    meta: {
      title: 'آتیا کراد | آشنایی با سکوی آتیا ایرانیان'
    }
  },
  {
    path: '/Investment',
    name: 'investment',
    component: Investment,
    meta: {
      title: 'آتیا کراد | ثبت نام و سرمایه گذاری'
    }
  },
  {
    path: '/Authentication',
    name: 'authentication',
    component: Authentication,
    meta: {
      title: 'آتیا کراد | احراز هویت'
    },
  },
  {
    path: '/Dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'آتیا کراد | پرتال سرمایه گذاری',
    },
    props: true,
    children: [{
        path: '/Dashboard',
        name: 'root',
        component: Root,
      },
      {
        path: '/Form/:entity_type',
        alias: ['/Form/:entity_type/:id'],
        name: 'form',
        component: Form,
        props: true,
        children: []
      },
      {
        path: '/List/:entity_type/:page/:size',
        name: 'list',
        component: List,
        props: true,
        children: []
      },
      {
        path: '/Transaction/:page/:size',
        name: 'transaction',
        component: Transaction,
        props: true,
        children: []
      },
      {
        path: '/Receipt/:id',
        name: 'receipt',
        component: Receipt,
        props: true
      },
      {
        path: '/Ticket',
        name: 'ticket',
        component: Ticket,
      },
      {
        path: '/In/:id',
        name: 'in',
        props: true,
        component: In
      },
      {
        path: '/Offer/New',
        name: 'offer',
        props: true,
        component: Offer
      },
      {
        path: '/Offer/Pending',
        name: 'offerp',
        props: true,
        component: OfferP
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
