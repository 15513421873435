<template>
    <div class="upload flex vcenter">
        <i v-if="meta.type === 'file' && vm && (meta.editable || !id)" class="x fe fe-16 fe-x" @click="del()"></i>
        <i v-if="meta.type === 'file' && vm" class="download fe fe-16 fe-download"
            :class="[{ 'central': !(meta.editable || !id) }]" @click="vm.source
                ? base.Entities().receive(vm)
                : base.Entities().download(vm)"></i>
        <div @click="this.$refs.choose.click()">
            <span v-if="meta.type === 'files'">
                <i class="fe fe-upload"></i>
                فایل را برای آپلود انتخاب کنید.
            </span>
            <input ref="choose" type="file" :multiple="meta.type === 'files'" :accept="accept ? accept
                .replaceAll('|', ',') : ''" hidden :disabled="!(meta.editable || !id)" @change="onchange" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import imageCompression from 'browser-image-compression'

export default {
    name: 'Upload',
    props: ['meta', 'vm', 'id'],
    inject: ['base'],
    data() {
        return {
            accept: this.base.Entities().parse(this.meta.extras)['ACCEPT'],
            size: Number(this.base.Entities().parse(this.meta.extras)['SIZE'])
        }
    },
    methods: {
        async onchange(event) {
            let files = []
            for (let index = 0; index < event.target.files.length; index++)
                if (!this.base.Util().validate(this.accept) || this.accept.includes(event.target.files[index].type))
                    if (!this.base.Util().validate(this.size) || (event.target.files[index].size / 1048576) <= this.size) {
                        let file = event.target.files[index]
                        if (file.type.includes('image/')) {
                            let id = this.base.Util().hash(4)
                            this.base.Notification().toast('نتیجه', `در حال بهینه سازی "${file.name}"`, 'success', id, 35000)
                            file = await imageCompression(file, {
                                maxSizeMB: 0.5,
                                useWebWorker: true
                            })
                            this.base.Notification().close(id)
                        }

                        files.push({
                            id: this.base.Util().hash(4),
                            extras: this.meta.key,
                            name: file.name,
                            type: file.type,
                            size: file.size,
                            raw: file,
                            prototype: 'FILE'
                        })
                    }
                    else
                        this.base.Notification().error({ code: 1002, text: event.target.files[index].name })
                else
                    this.base.Notification().error({ code: 1001, text: event.target.files[index].name })
            this.$emit('onchange', {
                value: this.meta.type === 'file'
                    ? files[0]
                    : files
            })
        },
        del() {
            if (this.vm.source)
                this.api.del('IO/Attachment/Delete', { data: { id: this.vm.id } }, (response) => {
                    if (this.base.Util().success(response)) {
                        this.base.Notification().toast('نتیجه'
                            , `فایل "${this.vm.name}" با موفقیت حذف گردید!`, 'success')
                        this.$emit('onchange', {})
                    }
                })
            else
                this.$emit('onchange', {})
        }
    }
}
</script>

<style scoped src="../../assets/css/upload.css"></style>