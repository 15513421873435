<template>
    <div class="flex center vcenter"
        :style="{ 'width': meta.style ? JSON.parse(meta.style)['width'] : '100%', 'min-height': '36px' }">
        <div class="tabs flex wrap space" :style="Object.assign(meta.style ? JSON.parse(meta.style) : ''
            , { 'width': 'auto !important' })">
            <template v-for="(option, index) in options" :key="index">
                <div :class="[{ 'active': value === option.id }, { 'disabled': disabled }]" @click.prevent="disabled
                    ? null
                    : value = option.id">
                    <p>{{ option.text }}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Tabs',
    props: ['meta', 'vm', 'disabled'],
    inject: ['base'],
    created() {
        this.base.Entities().data(this.meta.extras
            , this.meta.type === 'tab'
                || this.meta.extras.includes('key=data') ? 'dynamic' : 'static', (data) => this.options = data)
    },
    data() {
        return {
            options: [],
            value: this.vm
        }
    },
    watch: {
        value(newValue, oldValue) {
            this.$emit('onchange', {
                key: this.meta.key,
                value: this.value
            })
        }
    }
}
</script>

<style src="../../assets/css/tabs.css"></style>