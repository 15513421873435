/* eslint-disable */
import {
  createApp
} from 'vue'
import App from './App.vue'
import base from './base/base'
import router from './router'
import VueCookies from 'vue-cookies'
import Notifications from '@kyvg/vue3-notification'
import Vue3ConfirmDialog from 'vue3-confirm-dialog'
import StepProgress from 'vue-step-progress'
import Select2 from 'vue3-select2-component'

const app = createApp(App)

app.provide('base', base)

app.config.devtools = false

app.config.errorHandler = (error, instance, info) => {
  console.log(error)
}
router.beforeEach((to) => {
  const {
    title,
    description
  } = to.meta;
  const defaultTitle = 'آتیا کراد'
  const defaultDescription = ''

  document.title = title || defaultTitle

  const descriptionElement = document.querySelector('head meta[name="description"]')

  if (descriptionElement)
    descriptionElement.setAttribute('content', description || defaultDescription)
})
app.use(router).use(VueCookies).use(Notifications).use(Vue3ConfirmDialog)
app.component('Confirm', Vue3ConfirmDialog.default)
app.component('StepProgress', StepProgress)
app.component('Select2', Select2)
app.mount('#app')
