<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d205.17319434019447!2d50.88068157915161!3d34.63522409303433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDM4JzA2LjciTiA1MMKwNTInNTAuMiJF!5e0!3m2!1sen!2s!4v1715942593728!5m2!1sen!2s"
                    height="158" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                <hr>
                <div class="contact flex wrap center">
                    <div class="right flex wrap center">
                        <div class="head">
                            <h3>اطلاعات تماس</h3>
                        </div>
                        <div class="info flex wrap center">
                            <div class="flex wrap center">
                                <h5>آدرس</h5>
                                <p>قم، خیابان معلم غربی، مجتمع تجاری ناشران، طبقه 5، واحد 531</p>
                            </div>
                            <div class="phone flex wrap center">
                                <h5>مرکز تماس</h5>
                                <p>025-91004810</p>
                            </div>
                            <div class="phone flex wrap center">
                                <h5>واحد انفورماتیک</h5>
                                <p>025-91004810 داخلی 1001</p>
                            </div>
                            <div class="phone flex wrap center">
                                <h5>نمابر</h5>
                                <p>025-91004810 داخلی 9</p>
                            </div>
                            <div class="flex wrap center">
                                <h5>پست الکترونیکی</h5>
                                <p>info@atiyacrowd.ir</p>
                            </div>
                        </div>
                        <Social></Social>
                    </div>
                    <hr>
                    <div class="left flex wrap center">
                        <div class="head">
                            <h3>انتقادات و پیشنهادات</h3>
                        </div>
                        <div class="request flex wrap center vcenter">
                            <div class="params flex center vcenter">
                                <input type="text" placeholder="نام و نام خانوادگی">
                            </div>
                            <div class="params flex center vcenter">
                                <input type="text" placeholder="شماره همراه">
                            </div>
                            <div class="params flex center vcenter">
                                <textarea placeholder="توضیحات" value="" text="">
                                </textarea>
                            </div>
                            <div class="action flex center vcenter">
                                <div class="button">
                                    <button id="submit" :class="{ 'active': loading['submit'] }" type="button"
                                        @click.prevent="send()">
                                        <div class="loading">
                                            <span></span>
                                        </div>
                                        <p>ثبت و ارسال</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Social from '../components/Social.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Contact',
    components: { Header, Social, Footer },
    data() {
        return {
            loading: {}
        }
    },
    methods: {
        send() {
            this.loading['submit'] = true
            setTimeout(() => {
                this.loading = {}
            }, 10000)
        }
    }
}
</script>

<style scoped src="../assets/css/contact.css"></style>