<template>
    <div class="dragbox">
        <div v-if="meta.editable || !id">
            <Upload :meta="meta" @onchange="onchange"></Upload>
            <div class="hint flex wrap">
                <small v-if="base.Util().validate(meta.hint)">توضیحات: {{ meta.hint }}</small>
                <small>فرمت‌های قابل پشتیبانی: {{ accept }}</small>
            </div>
        </div>
        <div>
            <div class="files" v-if="files.length">
                <h5>فایل‌های انتخاب شده</h5>
                <ul>
                    <transition-group name="fade">
                        <li class="flex wrap vcenter" v-for="(file, index) in files" :key="index">
                            <span :class="{ 'uploaded': file.source }" @click.prevent="file.source
                                ? base.Entities().receive(file)
                                : base.Entities().download(file)">
                                {{ file.name }}
                            </span>
                            <div v-if="meta.editable || !file.source" @click.prevent="drop(file)">
                                <i class="fe fe-trash"></i>
                            </div>
                        </li>
                    </transition-group>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import entities from '@/base/entities';
import Upload from './Upload.vue'
import mime from 'mime'

export default {
    name: 'DragBox',
    props: ['meta', 'vm', 'id'],
    inject: ['base'],
    components: { Upload },
    created() {
        this.init()
        this.base.Entities().parse(this.meta.extras)['ACCEPT']?.split('|')
            .forEach((element) => {
                let extension = mime.getExtension(element)
                if (extension && !this.accept.includes(extension))
                    this.accept += this.base.Util().validate(this.accept)
                        ? `, ${extension}`
                        : extension
            })
    },
    data() {
        return {
            accept: '',
            count: Number(this.base.Entities().parse(this.meta.extras)['COUNT']),
            size: Number(this.base.Entities().parse(this.meta.extras)['MAX_SIZE']),
            files: []
        }
    },
    watch: {
        vm(newVM, oldVM) {
            this.files = this.vm
        },
        files: {
            async handler(newFiles, oldFiles) {
                this.files.prototype = 'FILE'
                this.$emit('onchange', {
                    key: this.meta.key,
                    value: this.files
                })
            },
            deep: true
        }
    },
    methods: {
        init() {
        },
        onchange(obj) {
            obj.value.forEach(async (file) => {
                let raw = this.files.find((r) => r.name === file.raw.name
                    && r.size === file.raw.size
                    && r.type === file.raw.type)

                let result = 0
                this.files.map(file => file.size).forEach((size) => {
                    result += size
                })

                if (!raw) {
                    if (!this.base.Util().validate(this.count) || this.files.length < this.count)
                        if (!this.base.Util().validate(this.size) || ((result + file.size) / 1048576) <= this.size)
                            this.files.push(file)
                        else
                            this.base.Notification().error({ code: 1003, text: file.name })
                    else
                        this.base.Notification().error({ code: 1004, text: file.name })
                }
            })
        },
        drop(file) {
            if (file.source)
                this.$confirm(
                    {
                        message: 'آیا از حذف ضمیمه اطمینان دارید؟',
                        button: {
                            no: 'خیر',
                            yes: 'بله'
                        },
                        callback: async confirm => {
                            if (confirm) {
                                this.base.API().del('IO/Attachment/Delete', { data: { id: file.id } }, (response) => {
                                    if (this.base.success(response)) {
                                        this.base.Notification().toast('نتیجه'
                                            , `فایل "${file.name}" با موفقیت حذف گردید!`, 'success')
                                        this.files = this.files.filter((f) => { return f.id !== file.id })
                                    }
                                })
                            }
                        }
                    }
                )
            else
                this.files = this.files.filter((f) => { return f.id !== file.id })
        },
    }
}
</script>

<style lang="scss" src="../../assets/css/dragbox.scss" />