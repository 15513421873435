<template>
  <RouterView :authenticated="authenticated" :path="path" :excluded="excluded" />
  <notifications position="top left" :duration="6000" :pauseOnHover="true" />
  <vue3-confirm-dialog />
</template>

<script>
/* eslint-disable */
import RouterView from 'vue-router'
import axios from 'axios'

export default {
  name: 'App',
  components: { RouterView },
  inject: ['access', 'api', 'session'],
  mounted() {
    window.RAYCHAT_TOKEN = '6b6772f6-78fb-49ae-990c-fe4b7a8ce4b9'
    let raychat = document.createElement('script')
    raychat.setAttribute('src', 'https://widget-react.raychat.io/install/widget.js')
    raychat.setAttribute('async', 1)
    document.head.appendChild(raychat)
  },
  created() {
    this.init()
  },
  data() {
    return {
      authenticated: this.$cookies.get('uid') && this.$cookies.get('gid') && this.$cookies.get('rid'),
      path: window.location.pathname.toLowerCase(),
      excluded: ['/', '/authentication', '/register', '/projects', '/project', '/contact'],
      ip: '0.0.0.0',
    }
  },
  methods: {
    init() {
      this.inspect(true)
      // this.routing()
      // this.session.identify(this.excluded)
      axios.get('https://api.ipify.org?format=json')
        .then((response) => {
          this.ip = response.data.ip
          localStorage.setItem('ipaddr', response ? response.data.ip : '0.0.0.0')
        })
      setInterval(() => {
        if (localStorage.getItem('ipaddr') !== this.ip)
          localStorage.setItem('ipaddr', this.ip)
      }, 1000)
    },
    inspect(enable) {
      if (!enable) {
        document.addEventListener('contextmenu', function (e) {
          e.preventDefault()
        })
        document.onkeydown = (e) => {
          if (e.key === 123) {
            e.preventDefault()
          }
          if (e.ctrlKey && e.shiftKey && e.key === 'I') {
            e.preventDefault()
          }
          if (e.ctrlKey && e.shiftKey && e.key === 'C') {
            e.preventDefault()
          }
          if (e.ctrlKey && e.shiftKey && e.key === 'J') {
            e.preventDefault()
          }
          if (e.ctrlKey && e.key === 'U') {
            e.preventDefault()
          }
        }
      }
    },
    routing() {
      if (!this.authenticated && !this.excluded.includes(this.path))
        window.location.pathname = '/Authentication'
      else if (this.authenticated && (this.path === '/authentication' || this.path === '/register'))
        window.location.pathname = '/Dashboard'
      // this.access.check(this.path)
    }
  }
}
</script>

<style src="../src/assets/css/main.css"></style>

<style lang="less" src="../src/assets/css/main.less" />

<style src="../src/assets/css/font.css"></style>

<style src="../src/assets/css/feather.css"></style>

<style src="../src/assets/fonts/icomoon/style.css"></style>

<style src="vue-step-progress/dist/main.css" />

<style src="../node_modules/vue3-confirm-dialog/dist/style.css" />