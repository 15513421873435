<template>
    <input :type="type()" :class="[{ 'has': value && base.Util().validate(String(value)) },
    { 'safe': action }]" v-model="value" :disabled="disabled" @focus="focus(true)" @blur="focus(false)"
        @keydown="keypress">
    <i v-if="action && vm && !error && authenticated" class="vscenter fe fe-16 fe-database"></i>
    <i v-if="this.meta.type === 'password' && vm" :class="[{ 'fe-eye': !toggle }, { 'fe-eye-off': toggle }]"
        class="vscenter toggle fe fe-16" @click="toggle = !toggle && !disabled"></i>
</template>

<script>
/* eslint-disable */
export default {
    name: 'TextBox',
    props: ['meta', 'vm', 'active', 'disabled', 'ctrl'],
    inject: ['base'],
    created() {
        this.length = (this.meta.type === 'mobile' || this.meta.type === 'tel')
            ? 12
            : this.meta.type === 'iban'
                ? 26
                : this.meta.type === 'date'
                    ? 10
                    : this.base.Entities().parse(this.meta.extras)['length']
        if (this.vm)
            this.handle()

        if (typeof (this.max) === 'string' && this.max.includes('env'))
            this.max = this.$cookies.get(this.max.replace('env[', '')
                .replace(']', '')) || '0'
    },
    data() {
        return {
            length: 0,
            min: this.base.Entities().parse(this.meta.extras)['min'] || 0,
            max: this.base.Entities().parse(this.meta.extras)['max'] || 1.7976931348623157e+308,
            value: this.vm || '',
            hint: '',
            error: false,
            action: this.meta.type === 'mobile' || this.meta.type === 'iban',
            toggle: false
        }
    },
    watch: {
        vm(newVM, oldVM) {
            this.value = this.vm
        },
        disabled(newDisabled, oldDisabled) {
            this.toggle = !this.disabled
        },
        active(newActive, oldActive) {
            // if (this.active)
            //     $('html, body').animate({
            //         scrollTop: 0
            //     }, "slow")
        },
        length(newLength, oldLength) {
            this.length = this.base.Util().validate(this.length) ? this.length : 2147483646
        },
        value(newValue, oldValue) {
            this.handle()
            this.$emit('onchange', {
                value: String(this.value),
                hint: this.hint,
                error: this.error
            })
        },
        hint(newHint, oldHint) {
            this.$emit('onchange', {
                value: String(this.value),
                hint: this.hint,
                error: this.error
            })
        }
    },
    methods: {
        type() {
            return (this.meta.type === 'mobile' || this.meta.type === 'tel')
                ? 'tel' : (this.meta.type === 'date' || this.meta.type === 'amount')
                    ? 'text'
                    : (this.meta.type === 'password' && this.toggle)
                        ? 'text' : this.meta.type
        },
        handle() {
            this.reformat()
            switch (this.meta.type) {
                case 'email':
                    this.email()
                    break
                case 'mobile':
                    this.mobile()
                    break
                case 'tel':
                    this.tel()
                    break
                case 'date':
                    this.date()
                    break
                case 'amount':
                    this.reformat()
                    break
                case 'password':
                    this.password()
                    break
                case 'number':
                    this.number()
                    break
                case 'iban':
                    this.iban()
                    break
                default:
                    break
            }
        },
        email() {
            this.error = this.value !== ''
            if (this.value.includes('@')) {
                let email = this.value.split('@')
                if (email[1].includes('.'))
                    this.error = !this.base.Util().validate(email[1].split('.')[1])
            }
        },
        mobile() {
            this.error = this.value !== '' && !(
                (['98900', '98901', '98902', '98903', '98904', '98905'
                    , '98910', '98911', '98912', '98913', '98914', '98915', '98916'
                    , '98917', '98918', '98919', '98990', '98991', '98992', '98993'
                    , '98994', '98996', '98930', '98933', '98935', '98936', '98937'
                    , '98938', '98939', '98941', '98920', '98921', '98922', '98998'
                    , '98999'].includes(this.value.slice(0, 5))) && this.value.length === 12)
        },
        tel() {
            this.error = this.value !== '' && !(
                (['9841', '9844', '9845', '9831', '9826', '9884', '9877', '9821', '9838'
                    , '9856', '9851', '9858', '9861', '9824', '9823', '9854', '9871', '9828', '9825', '9874'
                    , '9887', '9834', '9883', '9817', '9813', '9866', '9811', '9886', '9876', '9881', '9835'].includes(this.value.slice(0, 4))) && this.value.length === 12)
        },
        date() {
            let year = 0
            let month = 0
            let day = 0

            let value = this.value.split('/')
            if (value.length === 3) {
                year = Number(value[0])
                month = Number(value[1])
                day = Number(value[2])
            }

            this.error = this.value !== '' && !((year >= 1300 && year <= 1500) && ((month <= 6 && month >= 1 && day <= 31)
                || (month <= 12 && month >= 7 && day <= 30)) && day >= 1
                && ((month < 12) || (month === 12 && day < 30)
                    || (month === 12 && day === 30 && ['1435', '1431', '1427', '1423', '1419', '1415', '1411', '1407', '1403'
                        , '1399', '1395', '1391', '1387', '1383', '1379', '1375', '1370', '1366', '1362'
                        , '1358', '1354', '1350', '1346', '1342', '1337', '1333', '1329', '1325', '1321'
                        , '1317', '1313', '1309', '1300'].includes(value[0]))))
        },
        password() {
            this.error = this.value !== '' && !(/^(?=.*[0-9])(?=.*[!@#$%^&*~_-])[a-zA-Z0-9!@#$%^&*~_-]{8,24}$/)
                .test(this.value)
        },
        number() {
            this.error = this.value !== '' && !(this.min <= Number(this.value)
                && this.max >= Number(this.value))
        },
        iban() {
            let prefix = this.value.slice(4, 7)
            switch (prefix) {
                case '012':
                    this.hint = 'ملت'
                    break
                case '017':
                    this.hint = 'ملی'
                    break
                case '019':
                    this.hint = 'صادرات'
                    break
                case '015':
                    this.hint = 'سپه'
                    break
                case '018':
                    this.hint = 'تجارت'
                    break
                case '016':
                    this.hint = 'کشاورزی'
                    break
                case '014':
                    this.hint = 'مسکن'
                    break
                case '021':
                    this.hint = 'پست بانک'
                    break
                case '020':
                    this.hint = 'توسعه صادرات'
                    break
                case '057':
                    this.hint = 'پاسارگاد'
                    break
                case '054':
                    this.hint = 'پارسیان'
                    break
                case '055':
                    this.hint = 'اقتصاد نوین'
                    break
                case '013':
                    this.hint = 'رفاه'
                    break
                case '061':
                    this.hint = 'شهر'
                    break
                case '051':
                    this.hint = 'توسعه تعاون'
                    break
                case '062':
                    this.hint = 'آینده'
                    break
                case '058':
                    this.hint = 'سرمایه'
                    break
                case '056':
                    this.hint = 'سامان'
                    break
                case '059':
                    this.hint = 'سینا'
                    break
                case '011':
                    this.hint = 'صنعت و معدن'
                    break
                case '053':
                    this.hint = 'کار آفرین'
                    break
                default:
                    this.hint = ''
                    break
            }

            this.error = this.value !== '' && !(this.value.length === 26
                && this.base.Util().validate(this.hint))
        },
        reformat() {
            switch (this.meta.type) {
                case 'mobile':
                case 'tel':
                    if (this.value.length >= 2)
                        if (!this.value.startsWith('98'))
                            this.value = `98${this.value.slice(this.value.startsWith('0') ? 1 : 0
                                , this.value.length)}`
                        else if (this.value.startsWith('980'))
                            this.value = `98${this.value.slice(3, this.value.length)}`
                    break
                case 'date':
                    this.value = this.value.replaceAll('/', '').replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
                    break
                case 'amount':
                    this.value = this.value.replace(/\s/g, '').replace(/,/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    this.hint = this.value === '' || this.value.startsWith('0') ? ''
                        : this.value.replace(/\s/g, '').replace(/,/g, '').num2persian() + ' ریال'
                    break
                case 'iban':
                    if (this.value.length >= 2 && !this.value.startsWith('IR'))
                        this.value = `IR${this.value}`
                    break
                default:
                    break
            }
        },
        focus(status) {
            if (status === false && this.value !== '')
                if ((this.meta.type === 'number' || this.meta.type === 'amount')) {
                    let v = this.value
                    if (this.meta.type === 'number') {
                        this.value = this.min > Number(this.value.replace(/\s/g, '').replace(/,/g, ''))
                            ? this.min
                            : this.max < Number(this.value.replace(/\s/g, '').replace(/,/g, ''))
                                ? this.max
                                : this.value
                    }
                    if (this.meta.type === 'amount')
                        if (Number(this.min.replace(/\s/g, '').replace(/,/g, '')) > Number(this.value.replace(/\s/g, '').replace(/,/g, ''))) {
                            this.error = true
                            this.$emit('onchange', {
                                value: String(this.value),
                                hint: this.hint,
                                error: this.error
                            })
                            this.base.Notification().toast('هشدار', 'حداقل مبلغ سرمایه گذاری 1,000,000 ریال است!'
                                , 'error')
                        }
                        else if (Number(this.max.replace(/\s/g, '').replace(/,/g, '')) < Number(this.value.replace(/\s/g, '').replace(/,/g, ''))) {
                            this.error = true
                            this.$emit('onchange', {
                                value: String(this.value),
                                hint: this.hint,
                                error: this.error
                            })
                            this.base.Notification().toast('هشدار', 'حداکثر مبلغ سرمایه گذاری 20,000,000,000 ریال است!'
                                , 'error')
                        }

                    if (String(Math.round(Number(this.value.replace(/\s/g, '').replace(/,/g, '')) / 1000) * 1000) !== this.value.replace(/\s/g, '').replace(/,/g, '')) {
                        this.error = true
                        this.$emit('onchange', {
                            value: String(this.value),
                            hint: this.hint,
                            error: this.error
                        })
                        this.base.Notification().toast('هشدار', 'مبلغ می‌بایست ضریبی از 1000 ریال باشد!'
                            , 'error')
                    }

                    if (Number(this.min.replace(/\s/g, '').replace(/,/g, '')) <= Number(this.value.replace(/\s/g, '').replace(/,/g, ''))
                        && Number(this.max.replace(/\s/g, '').replace(/,/g, '')) >= Number(this.value.replace(/\s/g, '').replace(/,/g, ''))
                        && String(Math.round(Number(this.value.replace(/\s/g, '').replace(/,/g, '')) / 1000) * 1000) === this.value.replace(/\s/g, '').replace(/,/g, '')) {
                        this.error = false
                        this.$emit('onchange', {
                            value: String(this.value),
                            hint: this.hint,
                            error: this.error
                        })
                    }
                }
                else if (this.meta.type === 'date') {
                    let min = typeof (this.min) === 'string'
                        ? Number(this.base.Util().time({ params: this.min }))
                        : this.min
                    let max = typeof (this.max) === 'string'
                        ? Number(this.base.Util().time({ params: this.max }))
                        : this.max
                    this.value = min > Number(this.base.Util().time({ date: this.value }))
                        ? this.base.Util().number(this.base.Util().time({ unix: min }))
                        : max < Number(this.base.Util().time({ date: this.value }))
                            ? this.base.Util().number(this.base.Util().time({ unix: max }))
                            : this.value
                    if (this.base.Entities().parse(this.meta.extras)['exception'] === 'holidays')
                        while (this.base.Util().time({ holiday: this.value, thursday: true }))
                            this.value = this.base.Util().number(this.base.Util().time({
                                unix: Number(this.base.Util().time({ date: this.value }))
                                    + 86400000
                            }))
                }
            this.$emit('onfocus', status)
        },
        keypress(event) {
            let key = event.keyCode
            let length = (String(event.target.value).length >= this.length
                && event.target.selectionStart === event.target.selectionEnd) || !event.key.trim()
            let number = isNaN(event.key)
            let space = key === 32
            let general = (key !== 8 && key !== 9 && key !== 46 && key !== 116)
                && !(this.ctrl && (key === 65 || key === 88 || key === 67 || key === 86))
                && !(key === 37 || key === 38 || key === 39 || key === 40)
            if (general) {
                if ((this.meta.type === 'mobile' || this.meta.type === 'tel'
                    || this.meta.type === 'date' || this.meta.type === 'amount'
                    || this.meta.type === 'number') || this.meta.type === 'iban') {
                    if (length || number || space) {
                        event.preventDefault()
                        event.stopImmediatePropagation()
                        return
                    }
                } else if (length && !space) {
                    event.preventDefault()
                    event.stopImmediatePropagation()
                    return
                }
            }
        }
    }
}
</script>