<template>
    <div class="flex wrap center">
        <audio src="/sounds/pos.mp3"></audio>
        <div class="output"></div>
        <div class="content flex wrap center">
            <div class="receipt flex wrap center" :class="{ 'show': show }">
                <template v-for="(entity, index) in rows.entities" :key="index">
                    <div class="flex wrap center cstart">
                        <div class="status flex wrap center" :class="[{ 'active': entity.status === 100 }
                            , { 'fail': entity.status !== 100 }]">
                            <p v-if="entity.status === 100">{{ 'موفق' }}</p>
                            <p v-else>{{ 'ناموفق' }}</p>
                        </div>
                        <div class="line"></div>
                        <div class="row flex wrap space">
                            <p>شماره تراکنش</p>
                            <p>{{ entity.id }}</p>
                        </div>

                        <div class="row flex wrap space">
                            <p>تاریخ انجام تراکنش</p>
                            <p>{{ base.Util().time({ unix: Number(find(entity.id, 'paid_at')), time: true }) }}</p>
                        </div>

                        <div class="row flex wrap space">
                            <p>شناسه مرجع</p>
                            <p>{{ find(entity.id, 'referral_id') }}</p>
                        </div>

                        <div class="row flex wrap space">
                            <p>شناسه پیگیری</p>
                            <p>{{ find(entity.id, 'trace_id') }}</p>
                        </div>

                        <div class="row flex wrap space">
                            <p>مبلغ تراکنش</p>
                            <p>{{ find(entity.id, 'amount') }} ریال</p>
                        </div>

                        <div class="row flex wrap space">
                            <p>کانال تراکنش</p>
                            <p>درگاه پرداخت سداد</p>
                        </div>

                        <div class="row flex wrap center">
                            <img src="../assets/images/icon.png">
                        </div>
                        <Button :meta="{ key: 'redirect', name: 'برو به صورت وضعیت مالی' }"
                            @onclick="$router.push('/Transaction/1/15')"></Button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Button from './Inputs/Button.vue'

export default {
    name: 'Receipt',
    props: ['id'],
    inject: ['base'],
    components: { Button },
    created() {
        this.base.API().post('Finance/Transaction/Value', { data: { id: Number(this.id) } }
            , (response) => {
                if (this.base.Util().success(response)) {
                    this.rows.entities = response.data.entities
                    this.rows.values = response.data.data
                    const audio = document.querySelector("audio")
                    audio.volume = 1
                    audio.play()
                    setTimeout(() => this.show = true, 100)
                }
            }, (error) => { })

    },
    data() {
        return {
            show: false,
            rows: {
                entities: [],
                values: []
            },
        }
    },
    methods: {
        find(id, key) {
            return this.rows.values.find((value) =>
                Number(value.parent_id
                    .replace('AC', '').replace('GR', '')
                    .replace('RO', '').replace('FO', '')
                    .replace('FI', '').replace('VV', '')) === id && value.key === key)?.value || ''
        }
    }
}
</script>

<style scoped src="../assets/css/receipt.css"></style>