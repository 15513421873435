<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <div class="head flex wrap center">
                    <Tabs :meta="{
                        type: 'step',
                        extras: 'R0:key=intro,value=همه طرح‌ها;R1:key=finance,value=در انتظار تامین سرمایه;R2:key=process,value=در حال تامین سرمایه;R3:key=i,value=تامین سرمایه شده'
                    }" :vm="models" @onchange="onchange"></Tabs>
                    <div class="search flex wrap center">
                        <div class="flex center vcenter">
                            <input type="text" placeholder="مرا دریاب!">
                            <button>دریافتن</button>
                        </div>
                    </div>
                </div>
                <div class="projects flex wrap center">
                    <template v-for="(entity, index) in rows['entities']" :key="index">
                        <CardView :title="find(entity.id, 'folder_name')" :c_name="find(entity.id, 'company_name')"
                            :profit="find(entity.id, 'interest_rate')" :period="find(entity.id, 'period')"
                            :withdrawal="find(entity.id, 'dividend_period')" :symbols="find(entity.id, 'symobl')"
                            :id="entity.id">
                        </CardView>
                    </template>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Tabs from '../components/Inputs/Tabs.vue'
import CardView from '../components/CardView.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Projects',
    components: { Header, Tabs, CardView, Footer },
    inject: ['base'],
    created() {
        this.base.API().get('Identities/Folder/FList'
            , (response) => {
                if (this.base.Util().success(response)) {
                    this.rows['entities'] = response.data.entities
                    this.rows['values'] = response.data.data
                }
            }, (error) => {

            })
    },
    data() {
        return {
            tabs: [{ content: '' }, { content: '' },
            { content: '' }, { content: '' }],
            rows: { entities: [], values: [] }
        }
    },
    methods: {
        find(id, key) {
            return this.rows.values.find((value) =>
                Number(value.parent_id
                    .replace('AC', '').replace('GR', '')
                    .replace('RO', '').replace('FO', '')
                    .replace('FI', '').replace('VV', '')) === id && value.key === key)?.value || ''
        },
        onchange() {

        }
    }
}
</script>

<style scoped src="../assets/css/projects.css"></style>