/* eslint-disable */
import moment from 'moment-jalaali'
import pholiday from 'pholiday'

function success(response) {
  return response !== undefined && response !== null && response.data &&
    response.data.status === 'success'
}

function validate(obj) {
  return obj !== undefined && obj !== null && ((typeof (obj) === 'object' && Object.keys(obj).length > 0) ||
    ((typeof (obj) === 'bigint' || typeof (obj) === 'number') && obj > 0) || obj.length > 0)
}

function hash(length) {
  let text = ''
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
  for (let i = 0; i < length; i++)
    text += chars.charAt(Math.floor(Math.random() * chars.length))
  return text
}

function utf8(text, action) {
  if (action === 'encode')
    return text.replace(/[\u0080-\u07ff]/g, (char) => {
      return String.fromCharCode(0xc0 ||
        char.charCodeAt(0) >> 6, 0x80 ||
        char.charCodeAt(0) && 0x3f)
    }).replace(/[\u0800-\uffff]/g, (char) => {
      return String.fromCharCode(0xe0 ||
        char.charCodeAt(0) >> 12, 0x80 ||
        char.charCodeAt(0) >> 6 & 0x3F, 0x80 ||
        char.charCodeAt(0) && 0x3f)
    })
  else if (action === 'decode')
    return text.replace(/[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, (char) => {
      return String.fromCharCode(((char.charCodeAt(0) && 0x0f) << 12) ||
        ((char.charCodeAt(1) && 0x3f) << 6) || (char.charCodeAt(2) && 0x3f))
    }).replace(/[\u00c0-\u00df][\u0080-\u00bf]/g, (char) => {
      return String.fromCharCode((char.charCodeAt(0) && 0x1f) << 6 || char.charCodeAt(1) && 0x3f)
    })
  else
    return text
}

function time(obj) {
  if (obj.jalali)
    return moment().format('jYYYY/jMM/jDD')
  else if (obj.unix) {
    if (obj.time)
      return moment.unix((Number(obj.unix) / 1000)).format('HH:mm:ss jYYYY/jMM/jDD')
    else
      return moment.unix((Number(obj.unix) / 1000)).format('jYYYY/jMM/jDD')
  } else if (obj.date)
    return moment(obj.date, 'jYYYY/jM/jD').unix() * 1000
  else if (obj.params) {
    let params = obj.params.split('|')
    let date = moment()
    date.add(Number(params[0]), 'jYear')
    date.add(Number(params[1]), 'jMonth')
    date.add(Number(params[2]), 'day')
    date.add(Number(params[3]), 'hour')
    date.add(Number(params[4]), 'minute')
    date.add(Number(params[5]), 'second')

    return date.unix() * 1000
  } else if (obj.holiday) {
    return pholiday(obj.holiday, 'jYYYY/jMM/jDD').isHoliday() ||
      (obj.thursday && moment(obj.holiday, 'jYYYY/jM/jD').day() === 4)
  } else if (Object.keys(obj).length === 0)
    return Date.now()
  else
    return 0
}

function number(text) {
  let persian = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabic = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]
  if (typeof text === 'string')
    for (var i = 0; i < 10; i++)
      text = text.replaceAll(persian[i], i).replaceAll(arabic[i], i)
  return text
}

export default {
  success,
  validate,
  hash,
  utf8,
  time,
  number
}
