<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <div class="container">
                    <div class="flex wrap center">
                        <img src="../assets/images/icon.png" width="128" height="128">
                        <h4 style="width: 100%; text-align: center;">آشنایی با سکوی آتیه ایرانیان تحت شرکت بارمان آژند
                            قرن</h4>
                        <p>
                            سکوی تامین مالی جمعی آتیه ایرانیان یک پلتفرم تامین مالی جمعی تحت نهاد مالی مشاور
                            سرمایه گذاری فراز ایده نوآفرین تک (فاینتک) دارای مجوز از سازمان فرابورس ایران
                            به شماره مجوز به عنوان یک ابزار تامین مالی تحت شرکت بارمان آژند قرن ثبت شده نزد اداره ثبت
                            شرکتها
                            و موسسات غیرتجاری استان تهران به شماره ثبتی 570651 می باشد که با اتکا بر دانش و تجربه
                            مدیران،
                            مشاوران و متخصصان حوزه کسب و کار و بازار پول و سرمایه به شناسایی طرح ها و پروژه های مولد و
                            سودزا
                            و تامین سرمایه مورد نیاز آنها می نماید، شایان ذکر است این مجموعه با استفاده از ظرفیت های
                            موجود
                            در کشور ضمن توجه ویژه به حوزه دانش بنیان و کسب و کار های نوپا در تلاش است بستری امن را برای
                            سرمایه گذاران و سرمایه پذیران فعالان حوزه اقتصادی فراهم نماید.
                        </p>
                        <img class="directors" src="../assets/images/directors.png">
                        <Button
                            :meta="{ key: 'redirect', name: 'پیوستن به خانواده آتیا', style: JSON.stringify({ 'width': '30%' }) }"
                            @onclick="$router.push('/Authentication')"></Button>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Button from '../components/Inputs/Button.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Introduction',
    components: { Header, Button, Footer },
    data() {
        return {
            models: 'profit',
            introductions: [
                {
                    title: 'تأمین سرمایه سریع',
                    description: '',
                    image: '/images/icons/i_1.png'
                },
                {
                    title: 'بدون مسدودی وجه',
                    description: '',
                    image: '/images/icons/i_9.png'
                },
                {
                    title: 'سهولت در تأمین مالی',
                    description: '',
                    image: '/images/icons/i_19.png'
                },
                {
                    title: 'عمومیت و فراگیر بودن',
                    description: '',
                    image: '/images/icons/i_8.png'
                },
                {
                    title: 'توسعه شبکه مشتریان',
                    description: '',
                    image: '/images/icons/i_11.png'
                },
                {
                    title: 'شهرت و توسعه برند',
                    description: '',
                    image: '/images/icons/i_3.png'
                },
                {
                    title: 'صرفه جویی در هزینه ها',
                    description: '',
                    image: '/images/icons/i_10.png'
                },
                {
                    title: 'انعطاف در تسویه منابع',
                    description: '',
                    image: '/images/icons/i_21.png'
                },
                {
                    title: 'شرایط ویژه برای شرکت‌های دانش بنیان',
                    description: '',
                    image: '/images/icons/i_15.png'
                }],
            processes: ['ثبت درخواست'
                , 'بررسی اولیه توسط کارشناسان'
                , 'اعتبار سنجی طرح'
                , 'عقد قرارداد با متقاضی'
                , 'اخذ وثایق از متقاضی'
                , 'دریافت تائیدیه از نهاد مالی'
                , 'دریافت تائیدیه از فرابورس'
                , 'تأمین سرمایه از سرمایه گذاران'
                , 'پرداخت وجه تأمین شده'
                , 'نظارت برفرآیند اجرای تعهدات'
                , 'دریافت سود و اصل سرمایه'],
            icons: [
                'images/icons/i_18.png'
                , 'images/icons/i_8.png'
                , 'images/icons/i_3.png'
                , 'images/icons/i_21.png'
                , 'images/icons/i_13.png'
                , 'images/icons/i_11.png'
                , 'images/icons/i_17.png'
                , 'images/icons/i_7.png'
                , 'images/icons/i_1.png'
                , 'images/icons/i_9.png'
                , 'images/icons/i_20.png']
        }
    },
    methods: {
        onchange(obj) {
            this.models = obj.value
        }
    }
}
</script>

<style scoped src="../assets/css/introduction.css"></style>