<template>
    <div v-if="base.Util().validate(replies)" class="reply flex wrap center">
        <template v-for="(reply, index) in replies" :key="index">
            <div class="flex vend" :class="[{ 'right': reply.uid === Number($cookies.get('uid')) }
                , { 'left': reply.uid !== Number($cookies.get('uid')) }]">
                <img src="/images/profile.jpg" height="36" width="36">
                <div class="chat flex wrap center">
                    <p>{{ reply.message }}</p>
                    <div class="line"></div>
                    <p>
                        {{ users[`AC${reply.uid}`].name }}
                        <br>
                        {{ users[`AC${reply.uid}`].group }}
                    </p>
                </div>
                <span>
                    {{ base.Util().time({ unix: reply.created_at, time: true }) }}
                </span>
            </div>
        </template>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Reply',
    props: ['meta', 'id'],
    inject: ['base'],
    created() {
        if (this.id)
            this.base.API().post('Support/Reply/Find', { data: { request: Number(this.id), status: 100 } }
                , (response) => {
                    if (this.base.Util().success(response))
                        if (this.base.Util().validate(response.data.data)) {
                            this.replies = response.data.data
                                .sort((m1, m2) => (m1.created_at > m2.created_at) ? 1 : (m1.created_at < m2.created_at) ? -1 : 0)
                            this.replies.map(x => x['uid'])
                                .filter((reply, index, array) => array.indexOf(reply) === index)
                                .forEach(element => {
                                    this.users[`AC${element}`] = {}
                                    this.base.API().post('Data/Value/Find', {
                                        data: {
                                            parent_id: `AC${element}`,
                                            key: 'display_name'
                                        }
                                    }, (response) => {
                                        if (this.base.Util().success(response))
                                            if (this.base.Util().validate(response.data.data))
                                                this.users[`AC${element}`]['name'] = response.data.data[0].value
                                    })

                                    this.base.API().post('Data/Value/Find', {
                                        data: {
                                            parent_id: `AC${element}`,
                                            key: 'group'
                                        }
                                    }, (response) => {
                                        if (this.base.Util().success(response))
                                            if (this.base.Util().validate(response.data.data))
                                                this.base.API().post('Data/Value/Find', {
                                                    data: {
                                                        parent_id: `${response.data.data[0].value}`,
                                                        key: 'g_name'
                                                    }
                                                }, (response) => {
                                                    if (this.base.Util().success(response))
                                                        if (this.base.Util().validate(response.data.data))
                                                            this.users[`AC${element}`]['group'] = response.data.data[0].value
                                                })
                                    })
                                })
                        }
                })
    },
    data() {
        return {
            replies: [],
            users: {},
            c: `سیدمحمدامین ملکی
کارشناس امور مشتریان آتیا کراد

در شبکه های اجتماعی همراه ما باشید تا از آخرین اخبار و تخفیف ها مطلع شوید:
تلگرام|اینستاگرام|آپارات|توییتر|لینکدین|یوتیوب`,
            u: `عارف شهابی
کاربر آتیا`
        }
    }
}
</script>

<style scoped src="../../assets/css/reply.css"></style>