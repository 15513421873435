/* eslint-disable */
import api from './api'
import entities from './entities'
import notification from './notification'
import security from './security'
import util from './util'

function API() {
  return api
}

function Entities() {
  return entities
}

function Notification() {
  return notification
}

function Security() {
  return security
}

function Util() {
  return util
}


export default {
  API,
  Entities,
  Notification,
  Security,
  Util
}
