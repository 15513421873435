<template>
    <div class="accordion flex wrap center">
        <div class="title flex wrap center vcenter" :class="{ 'show': show }" @click.prevent="show = !show">
            <p>
                {{ title }}
            </p>
        </div>
        <div class="content flex wrap" :class="{ 'show': show }">
            <p>
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Accordion',
    props: ['title', 'description', 'default'],
    data() {
        return {
            show: this.default === 'open'
        }
    }
}
</script>

<style scoped src="../assets/css/accordion.css"></style>