<template>
    <div class="head">
        <div v-if="data.title && (!properties['notitle'] || properties['notitle'] === 'NO')" class="title">
            <h1>{{ data.title }}</h1>
        </div>
        <div v-if="!properties['filter'] || properties['filter'] === 'YES'" class="filter">
            <template v-for="(meta, index) in metas.filter((meta) => { return meta.type === 'tab' })" :key="index">
                <Tabs :meta="meta" :vm="models[meta.key]" @onchange="onchange">
                </Tabs>
            </template>
        </div>
        <div v-if="!properties['options'] || properties['options'] === 'YES'" class="options flex">
            <div class="right flex start vcenter">
                <Option :color="'blue'" :icon="'fe-search'" :name="'جست و جو'"></Option>
            </div>
            <div class="left flex end vcenter">
                <Option v-if="!properties['add'] || properties['add'] === 'YES'" :color="'green'" :icon="'fe-plus'"
                    :name="'افزودن'" @onclick="open"></Option>
                <Option v-if="!properties['remove'] || properties['remove'] === 'YES'" :color="'crimson'" :icon="'fe-x'"
                    :name="'حذف'"></Option>
            </div>
        </div>
    </div>
    <div class="table" @click.prevent="$emit('click')">
        <div class="thead flex start vcenter">
            <div class="control">
                <label>
                    <input type="checkbox" @click="() => { }" :checked="all" />
                    <div></div>
                </label>
            </div>
            <div v-if="properties['id'] === 'YES'" class="id flex wrap center vcenter">
                <p>شناسه سند</p>
                <span class="tooltip">{{ copied || 'شناسه سند' }}</span>
            </div>
            <template v-for="(meta, index) in metas.filter((meta) => { return meta.type !== 'tab' })" :key="index">
                <div v-show="visibilities[meta.key] === 'visible'" class="flex wrap center vcenter"
                    :class="{ 'override': base.Entities().parse(meta.extras)['column'] }"
                    :style="{ 'width': base.Entities().parse(meta.extras)['column'] || meta.style ? JSON.parse(meta.style)['width'] : '' }"
                    @click.prevent="copy(base.Entities().parse(meta.extras)['name'] || meta.name)">
                    <p>{{ value = base.Entities().parse(meta.extras)['name'] || meta.name }}</p>
                    <span class=" tooltip">{{ copied || value }}</span>
                </div>
            </template>
            <div v-if="properties['created_at'] === 'YES'" class="created_at flex wrap center vcenter">
                <p>{{ properties['created_at_title'] || 'تاریخ ثبت' }}</p>
                <span class="tooltip">{{ copied || properties['created_at_title'] || 'تاریخ ثبت' }}</span>
            </div>
            <div v-if="properties['status'] === 'YES'" class="status flex wrap center vcenter">
                <p>وضعیت</p>
                <span class="tooltip">{{ copied || 'وضعیت' }}</span>
            </div>
            <div v-if="properties['shield'] === 'YES'" class="shield">
            </div>
            <div v-if="!properties['view'] || properties['view'] === 'YES'
                || !properties['cross'] || properties['cross'] === 'YES'" class="operations">
            </div>
        </div>
        <div class="tbody flex wrap start vcenter">
            <template v-for="(entitiy, index) in rows.entities" :key="index">
                <row class="flex start vcenter">
                    <div class="control">
                        <label>
                            <input type="checkbox" @click="() => { }" :checked="all" />
                            <div></div>
                        </label>
                    </div>
                    <div v-if="properties['id'] === 'YES'" class="id flex wrap center vcenter">
                        <p>{{ entitiy.id }}</p>
                        <span class="tooltip">{{ copied || entitiy.id }}</span>
                    </div>
                    <template v-for="(meta, index) in metas.filter((meta) => { return meta.type !== 'tab' })"
                        :key="index">
                        <div v-show="visibilities[meta.key] === 'visible'" class="flex wrap center vcenter"
                            :class="[{ 'right': !base.Util().validate(value) }, { 'override': base.Entities().parse(meta.extras)['column'] }]"
                            :style="{ 'width': base.Entities().parse(meta.extras)['column'] || meta.style ? JSON.parse(meta.style)['width'] : '' }
                                "
                            @click.prevent="copy(rows.values.find((value) => Number(value.parent_id
                                .replace('AC', '').replace('GR', '')
                                .replace('RO', '').replace('FO', '')
                                .replace('FI', '').replace('VV', '')) === entitiy.id && value.key === meta.key)?.value)">
                            <p v-if="meta.type !== 'switch'">{{ value = meta.type === 'date'
                                ? base.Util().time({ unix: Number(find(entitiy.id, meta.key)), time: true }) || ''
                                : meta.type === 'amount'
                                    ? find(entitiy.id, meta.key)?.replace(/\s/g, '').replace(/,/g,
                                        '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : find(entitiy.id, meta.key)
                                }}</p>
                            <i v-else class="fe fe-16 fe-x" :class="{ 'fe-check': find(entitiy.id, meta.key).toLowerCase() === 'true' || find(entitiy.id, meta.key) === true }
                                "></i>
                            <span v-if="meta.type !== 'switch'" class="tooltip">{{ copied || (meta.type === 'amount'
                                ? value.replace(/\s/g, '').replace(/,/g, '').num2persian() + ' ریال' : value) }}</span>
                        </div>
                    </template>
                    <div v-if="properties['created_at'] === 'YES'" class="created_at flex wrap center vcenter">
                        <p>{{ base.Util().time({ unix: entitiy.created_at, time: true }) }}</p>
                        <span class="tooltip">{{ copied || base.Util().time({
                            unix: entitiy.created_at, time: true
                        }) }}</span>
                    </div>
                    <div v-if="properties['status'] === 'YES'" class="status flex wrap center vcenter">
                        <p
                            :class="[{ 'active': entitiy.status === 100 }
                                , { 'pending': entitiy.status === 103 }, { 'failed': entitiy.status === 108 || entitiy.status === 101 }]">
                            {{ properties[entitiy.status] || entitiy.status }}</p>
                        <span class="tooltip">{{ copied || properties[entitiy.status] || entitiy.status }}</span>
                    </div>
                    <div v-if="properties['shield'] === 'YES'" class="shield flex wrap center vcenter">
                        <i class="fe fe-16 fe-shield"></i>
                    </div>
                    <div v-show="!properties['view'] || properties['view'] === 'YES'
                        || !properties['cross'] || properties['cross'] === 'YES'"
                        class="flex center vcenter operations">
                        <img v-if="!properties['view'] || properties['view'] === 'YES'" src="/images/search.png"
                            width="24" height="24" alt="add" @click.prevent="open(entitiy.id)">
                        <img v-if="!properties['cross'] || properties['cross'] === 'YES'" src="/images/cross.png"
                            width="24" height="24" alt="delete">
                    </div>
                </row>
            </template>
        </div>
        <div class="tfooter flex center vcenter">
            <div class="pagination flex wrap center vcenter">
                <template v-for="(page, index) in paginations" :key="index">
                    <div class="flex wrap center vcenter" :class="[{ 'active': Number(this.page) === page }]"
                        @click.prevent="navigate(page)">
                        <p>{{ page }}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <!-- <Dialog :data="dialog[dialog.active]"></Dialog> -->
</template>

<script>
/* eslint-disable */
import Option from '../components/Inputs/Option.vue'
import Dialog from '../components/Dialog.vue'
import Tabs from './Inputs/Tabs.vue'
import base from '@/base/base';

export default {
    name: 'List',
    props: ['entity_type', 'page', 'size'],
    inject: ['base'],
    components: { Option, Dialog, Tabs },
    mounted() {

    },
    created() {
        this.init()
        if (this.entity_type.toUpperCase() === 'FOLDER') {
            this.metas.forEach((meta) => {
                meta.extras = ''
                this.visibilities[meta.key] = 'visible'
            })
        }
    },
    data() {
        return {
            data: eval(localStorage.getItem('entities'))?.filter((entity) => {
                return entity.entity_type === this.entity_type.toUpperCase()
            })[0],
            properties: {},
            options: {},
            metas: eval(localStorage.getItem('metas')).filter((meta) => {
                return meta.entity_type === this.entity_type.toUpperCase()
                    && !(meta.type === 'avatar' || meta.type === 'files'
                        || meta.type === 'file' || meta.type === 'step'
                        || meta.type === 'textarea' || meta.type === 'password'
                        || meta.type === 'multiparams' || meta.type === 'span'
                        || meta.type === 'space' || meta.type === 'button') && meta.listable
            }).sort((m1, m2) => (Number(this.base.Entities().parse(m1.extras)['order']) > Number(this.base.Entities().parse(m2.extras)['order']))
                ? 1 : (Number(this.base.Entities().parse(m1.extras)['order']) < Number(this.base.Entities().parse(m2.extras)['order'])) ? -1 : 0),
            rows: {
                entities: [],
                values: []
            },
            models: {},
            visibilities: {},
            copied: '',
            pages: 0,
            paginations: [],
            dialog: { search: { title: 'جست و جو', entity_type: this.entity_type }, active: 'search' },
        }
    },
    watch: {
        models: {
            handler(newModels, oldModels) {
                this.visibility()
            },
            deep: true
        }
    },
    methods: {
        init() {
            this.properties = this.base.Entities().parse(this.data.properties)

            this.metas.forEach((meta) => {
                if (meta.extras && meta.extras.includes('key=default')
                    && !this.base.Util().validate(this.models[meta.key]))
                    this.models[meta.key] = this.base.Entities().parse(meta.extras)['default']

                meta.order = this.base.Entities().parse(meta.extras)['order'] || meta.order
            })

            this.metas = this.metas.sort((m1, m2) => (m1.order > m2.order
                ? 1 : (m1.order < m2.order) ? -1 : 0))

            this.visibility()

            this.base.API().post(`${this.properties['QUERY']}?uid=${this.$cookies.get('uid')}&rid=
            ${this.$cookies.get('rid')}&page=${this.page}&size=${this.size}`, {
                data: this.base.Entities().search() || {}
            }, (response) => {
                if (this.base.Util().success(response)) {
                    this.rows['entities'] = response.data.entities
                    this.rows['values'] = response.data.data

                    this.pages = Math.ceil(Number(response.data.count) / this.size)

                    if (this.pages >= this.page) {
                        if (this.pages > 3 && this.page > 1)
                            this.paginations.push('<<')
                        if (this.page > 1)
                            this.paginations.push('<')
                        if (this.page >= 3)
                            this.paginations.push(Number(this.page) - 2)
                        if (this.page >= 2)
                            this.paginations.push(Number(this.page) - 1)
                        this.paginations.push(Number(this.page))
                        if (this.pages >= Number(this.page) + 1)
                            this.paginations.push(Number(this.page) + 1)
                        if (this.pages >= Number(this.page) + 2)
                            this.paginations.push(Number(this.page) + 2)
                        if (this.page < this.pages) {
                            this.paginations.push('>')
                            if (this.pages > 3)
                                this.paginations.push('>>')
                        }
                    }
                }
            }, (error) => {

            })
        },
        onchange(obj) {
            this.models[obj.key] = obj.value
        },
        visibility() {
            this.metas.forEach((meta) => {
                if (meta.extras && meta.extras.includes('key=v-key')) {
                    let extras = this.base.Entities().parse(meta.extras)
                    if (extras) {
                        let key = extras['v-key']
                        if (typeof (key) === 'string')
                            this.visibilities[meta.key] = this.models[key] === extras[key]
                                ? 'visible'
                                : 'gone'
                        else if (Array.isArray(key)) {
                            let visibility = 'visible'
                            for (const element in key) {
                                if (this.models[key[element]] !== extras[key[element]]) {
                                    visibility = 'gone'
                                    break
                                }
                            }
                            this.visibilities[meta.key] = visibility
                        }
                    }
                } else
                    this.visibilities[meta.key] = 'visible'
            })
        },
        open(id) {
            let path = `/Form/${this.entity_type}`
            if (this.base.Util().validate(id))
                path += `/${id}`
            window.open(path)
        },
        find(id, key) {
            return this.rows.values.find((value) =>
                Number(value.parent_id
                    .replace('AC', '').replace('GR', '')
                    .replace('RO', '').replace('FO', '')
                    .replace('FI', '').replace('VV', '')) === id && value.key === key)?.value || ''
        },
        copy(value) {
            navigator.clipboard.writeText(value)
            this.copied = 'کپی شد!'
            setTimeout(() => this.copied = '', 500)
        },
        navigate(destination) {
            window.location.href = window.location.pathname.replace(`/${this.page}/${this.size}`
                , `/${destination === '<<' ? 1 : destination === '>'
                    ? Number(this.page) + 1 : destination === '<'
                        ? Number(this.page) - 1 : destination === '>>'
                            ? this.pages : destination}/${this.size}`) + window.location.search
        }
    }
}
</script>

<style src="../assets/css/list.css"></style>
