<template>
    <textarea :class="[{ 'has': value && base.Util().validate(String(value)) }]" v-model="value" :disabled="disabled"
        @focus="$emit('onfocus', true)" @blur="$emit('onfocus', false)" @keydown="keypress"></textarea>
</template>

<script>
/* eslint-disable */
export default {
    name: 'TextArea',
    props: ['meta', 'vm', 'active', 'disabled', 'ctrl'],
    inject: ['base'],
    data() {
        return {
            value: this.vm
        }
    },
    watch: {
        vm(newVM, oldVM) {
            this.value = this.vm
        },
        active(newActive, oldActive) {
            if (this.active)
                $('html, body').animate({
                    scrollTop: 0
                }, "slow")
        },
        value(newValue, oldValue) {
            this.$emit('onchange', {
                value: String(this.value),
                hint: this.hint,
                error: this.error
            })
        }
    },
    methods: {
        keypress(event) {
            let key = event.keyCode
            let length = (String(event.target.value).length >= this.meta.length
                && event.target.selectionStart === event.target.selectionEnd) || !event.key.trim()
            let space = key === 32
            let general = (key !== 8 && key !== 9 && key !== 46 && key !== 116)
                && !(this.ctrl && (key === 65 || key === 88 || key === 67 || key === 86))
                && !(key === 37 || key === 38 || key === 39 || key === 40)
            if (general)
                if (length && !space) {
                    event.preventDefault()
                    event.stopImmediatePropagation()
                    return
                }
        }
    }
}
</script>