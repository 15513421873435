<template>
    <div class="footers flex wrap center vcenter">
        <div class="row">
            <div class="body flex wrap space">
                <div class="c1 flex start">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d205.17319434019447!2d50.88068157915161!3d34.63522409303433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDM4JzA2LjciTiA1MMKwNTInNTAuMiJF!5e0!3m2!1sen!2s!4v1715942593728!5m2!1sen!2s"
                        height="169" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="c2">
                    <h3>اطلاعات تماس</h3>
                    <p>آدرس: قم، خیابان معلم غربی، مجتمع تجاری ناشران، طبقه 5، واحد 531</p>
                    <p>مرکز تماس: 91004810-025</p>
                    <p>نمابر: 91004810-025 داخلی 9</p>
                    <p>پست الکترونیکی: info@atiyacrowd.ir</p>
                </div>
                <div class="c3">
                    <h3>پیوند های مرتبط</h3>
                    <ul>
                        <li><a>فرابورس</a></li>
                        <li><a>سازمان بورس</a></li>
                        <li><a>بانک مرکزی</a></li>
                        <li><a>صندوق پژوهش فناوری</a></li>
                    </ul>
                </div>
                <div class="c4 flex vcenter" style="position: relative;">
                    <div class="trusted flex">
                        <div class="flex vcenter">
                            <a>
                                <img src="/images/tech.png" />
                            </a>
                        </div>
                        <div class="flex vcenter">
                            <a referrerpolicy="origin" target="_blank"
                                href="https://trustseal.enamad.ir/?id=505426&Code=ggikIv9ZQHKRpwCa93ngqsyVu88qJ41e"
                                rel="noopener">
                                <img referrerpolicy="origin"
                                    src="/images/enamad.png?id=397664&Code=gM6tvxmrUxWwipOe4S3RrjuUujhtXPZj" alt=""
                                    style="cursor:pointer" code="ggikIv9ZQHKRpwCa93ngqsyVu88qJ41e">
                            </a>
                        </div>
                        <div class="flex vcenter">
                            <a>
                                <img referrerpolicy='origin' id='rgvjfukzoeukfukzapfunbqe' style='cursor:pointer'
                                    onclick='window.open("https://logo.samandehi.ir/Verify.aspx?id=368652&p=xlaogvkamcsigvkadshwuiwk", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")'
                                    alt='logo-samandehi'
                                    src='https://logo.samandehi.ir/logo.aspx?id=368652&p=qftiwlbqaqgwwlbqujynodrf' />
                            </a>
                        </div>
                        <div class="flex vcenter">
                            <a>
                                <img src="/images/bonyan.png" />
                            </a>
                        </div>
                    </div>
                    <!-- <div
                        style="position: absolute; bottom: 0; background-color: transparent; width: 100%; display: flex; align-items: end; height: auto; min-height: auto;">
                        <p style="font-size: 14px"></p>
                    </div> -->
                </div>
            </div>
            <div class=" footer flex wrap center vcenter">
                <hr>
                <p>تمامی حقوق مادی و معنوی برای آتیه ایرانیان محفوظ است.</p>
                <div class="social">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Footer'
}
</script>

<style src="../assets/css/footer.css"></style>