<template>
    <nav>
        <aside :class="{ 'opened': navside }">
            <div class="x flex center vcenter">
                <i class="fe fe-16 fe-x" @click="navside = false"></i>
            </div>
            <div class="option">
                <ul>
                    <li><a href="/">صفحه نخست</a></li>
                    <li :class="{ 'opened': dropdown }">
                        <div class="dropdown flex wrap space vcenter">
                            <a href="#" @click="dropdown = !dropdown">آتیه ایرانیان</a>
                            <i class="fe fe-chevron-down fe-16" @click="dropdown = !dropdown"></i>
                            <div class="items wrap">
                                <a href="/Introduction">آشنایی با سکو آتیه ایرانیان</a>
                                <a href="/Funding">ثبت طرح و تأمین سرمایه</a>
                                <a href="/Investment">ثبت نام و سرمایه گذاری</a>
                                <a href="#services">سرویس‌ها و خدمات</a>
                            </div>
                        </div>
                    </li>
                    <li><a href="https://blog.atiyacrowd.ir/education">مرکز آموزش</a></li>
                    <li><a href="https://blog.atiyacrowd.ir/news">خبرنامه</a></li>
                    <li><a href="/Contact">تماس باما</a></li>
                </ul>
                <div class="flex wrap center vcenter">
                    <a href="/Authentication">
                        <button>پرتال سرمایه گذاری</button>
                    </a>
                </div>
            </div>
        </aside>
        <div class="row">
            <div class="menu flex vcenter">
                <div class="toggle">
                    <i class="fe fe-16 fe-menu" @click="navside = true"></i>
                </div>
                <div class="brand">
                    <img src="../assets/images/hlogo.png" />
                </div>
                <div class="right flex wrap center">
                    <div class="option">
                        <ul class="flex wrap center vcenter">
                            <li><a href="/">صفحه نخست</a></li>
                            <li>
                                <div class="dropdown">
                                    <a href="#">آتیه ایرانیان <i class="fe fe-chevron-down fe-16"></i></a>
                                    <div class="items wrap">
                                        <a href="/Introduction">آشنایی با سکو آتیه ایرانیان</a>
                                        <a href="/Funding">ثبت طرح و تأمین سرمایه</a>
                                        <a href="/Investment">ثبت نام و سرمایه گذاری</a>
                                        <a href="#services">سرویس‌ها و خدمات</a>
                                    </div>
                                </div>
                            </li>
                            <li><a href="https://blog.atiyacrowd.ir/education">مرکز آموزش</a></li>
                            <li><a href="https://blog.atiyacrowd.ir/news">خبرنامه</a></li>
                            <li><a href="/Contact" target="_blank">تماس باما</a></li>
                        </ul>
                    </div>
                </div>
                <div class="left flex wrap end vcenter">
                    <span>شماره ثبت: 570651</span>
                    <a :href="portal['href']" target="_blank">
                        <button>{{ portal['content'] }}</button>
                    </a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Header',
    created() {
        if (this.$cookies.get('display_name')) {
            this.portal['href'] = '/Dashboard'
            this.portal['content'] = this.$cookies.get('display_name')
        }
    },
    data() {
        return {
            navside: false,
            dropdown: false,
            portal: {
                href: '/Authentication',
                content: 'پرتابل سرمایه گذاری'
            }
        }
    }
}
</script>

<style src="../assets/css/header.css"></style>