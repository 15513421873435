/* eslint-disable */
import axios from 'axios'
import cookies from 'vue-cookies'
import security from './security'

function url(path) {
  return `https://beta.atiyacrowd.com/${path}`
}

// function url(path) {
//   return `https://localhost:7221/${path}`
// }

function header(type = 'application/json') {
  return {
    headers: {
      'Content-Type': type,
      'secret': security.generate(),
      'uuid': `${cookies.get('uid')};${cookies.get('gid')};${cookies.get('rid')}`,
      'ip': localStorage.getItem('ipaddr'),
      'agent': `Web ${window.navigator.userAgent}`
    }
  }
}

function body(obj) {
  return Object.assign({
    uuid: `${cookies.get('uid')};${cookies.get('gid')};${cookies.get('rid')}`
  }, obj)
}

function get(endpoint, res, err = () => {}) {
  axios.get(url(endpoint), header())
    .then((response) => res(response))
    .catch((error) => onerror(error.response, err))
}

function post(endpoint, payload = {}, res = () => {}, err = () => {}) {
  axios.post(url(endpoint), body(payload), header())
    .then((response) => res(response))
    .catch((error) => onerror(error.response, err))
}

function put(endpoint, payload = {}, res = () => {}, err = () => {}) {
  axios.put(url(endpoint), body(payload), header())
    .then((response) => res(response))
    .catch((error) => onerror(error.response, err))
}

function del(endpoint, payload = {}, res = () => {}, err = () => {}) {
  axios.delete(url(endpoint), Object.assign(header(), {
      data: body(payload)
    }))
    .then((response) => res(response))
    .catch((error) => onerror(error.response, err))
}

async function upload(parent_id, files) {
  const Form = require('form-data')
  const form = new Form()
  files.forEach(file => {
    if (file.raw)
      form.append('files', file.raw, file.name + `|${file.extras}`)
  })

  return await axios.post(url(`/IO/Upload?parent_id=${parent_id}`),
    form, header('multipart/form-data')).catch(() => onerror(error.response))
}

async function download(source, res = () => {}, err = () => {}) {
  return axios.get(url(`/IO/Download?source=${source}`), {
    headers: header().headers,
    responseType: 'arraybuffer'
  }).then((response) => {
    res(response)
  }).catch((error) => onerror(error.response, err))
}

function onerror(response, error = () => {}) {
  if (response && (response.status >= 500 || response.status === 403 || response.status === 401))
    localStorage.setItem('status', '0')

  if (response && (response.status === 406 || response.status === 403)) {
    let name = cookies.get('display_name')
    if (response.status === 403)
      cookies.set('message', `${name ? name : 'کاربر'} عزیز؛ 
دسترسی شما از سرور مسدود گردیده، لطفا جهت رفع مشکل با شماره 91004810-025 داخلی 929 تماس حاصل فرمایید.
آدرس اینترنتی: ${localStorage.getItem('ipaddr')}`)
    else if (response.status === '406')
      cookies.set('message', `${name ? name : 'کاربر'} عزیز؛
    مدت زمان استفاده شما از پرتال به اتمام رسیده، لطفا مجددا احرازهویت کنید.`)
    // security.logout()
  }
  error()
}

export default {
  get,
  post,
  put,
  del,
  upload,
  download
}
