<template>
    <div class="dialog" :class="[{ 'fullwidth': data.fullwidth }
        , { 'halfwidth': data.halfwidth }, { 'single': data.single }]">
        <div v-if="data.type !== 'form'" class="head">
            <div class="title">
                <h1>{{ data.title }}</h1>
            </div>
        </div>
        <div class="body">
            <Form v-if="data.type === 'form'" :entity_type="data.entity_type" :entity="data.data" @onresult="onresult">
            </Form>
            <div v-else-if="data.type === 'text'">
                <div class="flex center vcenter" :style="{ 'width': '100%' }">
                    <span :style="{ 'width': '100% !important' }">
                        {{ data.description }}
                    </span>
                </div>
                <Accordion :title="data.extras.title" :description="data.extras.entity.description"
                    :default="data.extras.entity.default"></Accordion>
                <Button :meta="data.button" :active="active" @onclick="onclick"></Button>
            </div>
            <div v-else>
                <template v-for="(key, index) in Object.keys(models)" :key="index">
                    <row v-if="!models[key].delete" class="flex wrap center vcenter">
                        <MetaBox :meta="{
                            key: 'key', name: 'شاخص', type: 'dropdown'
                            , extras: `R0:key=data,value=${keys}`
                        }" :vm="models[key]['key']" @onchange="(obj) => this.models[`R${index}`][obj.key] = obj.value">
                        </MetaBox>
                        <Switch
                            v-if="metas.find((meta) => meta.key === this.models[`R${index}`]['key'])?.type === 'switch'"
                            :meta="meta" :vm="models[key][meta.key]" :ctrl="ctrl"
                            @onchange="(obj) => this.models[`R${index}`][obj.key] = obj.value">
                        </Switch>
                        <MetaBox v-else :meta="metas.find((meta) => meta.key === this.models[key]['key'])"
                            :vm="models[key][this.models[`R${index}`]['key']]" :ctrl="ctrl" @onchange="(obj) => { }">
                        </MetaBox>
                        <div class="controls flex wrap center vcenter">
                            <img src="/images/plus.png" width="24" height="24" alt="add" @click="add">
                            <img v-if="length > 1" src="/images/xmark.png" width="24" height="24" alt="delete"
                                @click="() => models[key] = { delete: true }">
                        </div>
                    </row>
                </template>
                <div class="options flex center">
                    <Option :color="'blue'" :icon="'fe-search'" :name="'جست و جو'"></Option>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Form from './Form.vue'
import MetaBox from './Inputs/MetaBox.vue'
import Switch from './Inputs/Switch.vue'
import Option from '../components/Inputs/Option.vue'
import Button from '../components/Inputs/Button.vue'
import Accordion from '../components/Accordion.vue'

export default {
    name: 'Dialog',
    props: ['data'],
    inject: ['base'],
    components: { Form, MetaBox, Switch, Option, Accordion, Button },
    created() {
        this.add()
    },
    data() {
        return {
            metas: eval(localStorage.getItem('metas')).filter((meta) => {
                return meta.entity_type === this.data.entity_type?.toUpperCase()
                    && !(meta.type === 'avatar' || meta.type === 'files'
                        || meta.type === 'file' || meta.type === 'password'
                        || meta.type === 'multiparams' || meta.type === 'button')
            }),
            keys: '',
            models: {},
            length: 0,
            active: false
        }
    },
    watch: {
        models: {
            handler(newModels, oldModels) {
                this.length = 0
                Object.keys(this.models).forEach(key => {
                    if (!this.models[key].delete)
                        this.length += 1
                })
            },
            deep: true
        }
    },
    methods: {
        add() {
            this.key()
            let keys = Object.keys(this.models)
            let index = this.base.Util().validate(keys)
                ? Number(keys[keys.length - 1].replaceAll('R', '')) + 1
                : 0
            this.models[`R${index}`] = { key: `${this.keys.split('|')[0].split('%')[0]}` }
        },
        key() {
            let keys = ''
            this.metas.forEach(meta => {
                let exists = false
                for (const key of Object.keys(this.models))
                    if (meta.key === this.models[key].key)
                        exists = true

                if (!exists)
                    keys += this.base.Util().validate(keys)
                        ? `|${meta.key}%${meta.name}`
                        : `${meta.key}%${meta.name}`
            })
            this.keys = keys
        },
        onresult(obj) {
            this.$emit('onresult', obj)
        },
        onclick(obj) {
            this.active = true
            this.$emit('onclick', this.data.extras.entity.id, () => this.active = false)
        }
    }
}
</script>

<style scoped src="../assets/css/dialog.css"></style>